import SpecialistApi from "../../api/specialistsApi";
import {
  Education,
  Federation,
  Qualification,
} from "../../types/TypeSpecialist";
import Notify from "../../utils/toaster";

type CertificatesPaylaod = {
  filters: {
    specialization?: string[];
    isShown?: boolean;
  };
  page: number;
  limit: number;
};

export const getAllEducations = async (
  token: string | null,
  payload: CertificatesPaylaod,
  setData: (educations: Education[]) => void
) => {
  if (!token) return;

  const response = await SpecialistApi.getAllEducations(token, payload);

  if (!response.status && response.message) {
    return Notify(response.message);
  }
  if (response.status && response.educations) {
    setData(response.educations);
  }
};

export const getAllFederations = async (
  token: string | null,
  payload: CertificatesPaylaod,
  setData: (federations: Federation[]) => void
) => {
  if (!token) return;
  const response = await SpecialistApi.getAllFederations(token, payload);
  if (!response.status && response.message) {
    return Notify(response.message);
  }
  if (response.status && response.federations) {
    setData(response.federations);
  }
};

export const getAllQualifications = async (
  token: string | null,
  payload: CertificatesPaylaod,
  setData: (qualifications: Qualification[]) => void
) => {
  if (!token) return;
  const response = await SpecialistApi.getAllQualifications(token, payload);
  if (!response.status && response.message) {
    return Notify(response.message);
  }
  if (response.status && response.qualifications) {
    setData(response.qualifications);
  }
};
