import { useEffect, useState } from "react";
import UsersApi from "../../../api/usersApi";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import { DefaultComments } from "../../../types/TypeSession";
import { getTranslation } from "../../../utils/getTranslation";
import s from "./CommentCard.module.css";

type CommentCardProps = {
  id: string;
  commentMessage: string;
  staticComments: DefaultComments[];
  date: string;
};

const CommentCard = ({
  id,
  commentMessage,
  date,
  staticComments,
}: CommentCardProps) => {
  const token = localStorage.getItem("token");
  const [commentByFullname, setCommentByFullname] = useState("");

  useEffect(() => {
    if (!token || !id) return;
    const makeAsync = async () => {
      const response = await UsersApi.getUserPublicAvatarAndName(id);

      if (response.name && response.surname) {
        setCommentByFullname(
          getTranslation(response.name) + " " + getTranslation(response.surname)
        );
      }
    };
    makeAsync();
  }, [token, id]);

  function formatDate(isoString: string): string {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    const [datePart, timePart] = formattedDate.split(", ");

    return `${datePart} -${timePart}`;
  }

  return (
    <div className={s.container}>
      <div className={s.nameAvatarBlock}>
        <CircleAvatar userId={id} />
        <div>{commentByFullname}</div>
      </div>
      <div className={s.commentTextBlock}>
        <p className={s.commentText}>{commentMessage}</p>
        {staticComments.map((item) => (
          <p key={item.comment} className={s.commentText}>
            {
              item.labels.find(
                (lable) => lable.language === "en" // change to selected language
              )?.text
            }
          </p>
        ))}
      </div>

      <div className={s.dateBlock}>{formatDate(date)}</div>
    </div>
  );
};

export default CommentCard;
