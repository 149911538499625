import { useEffect, useState } from "react";
import CompanyApi from "../../api/companyApi";
import { ReactComponent as ChevronLeftIcon } from "../../assets/Specialists/chevronLeft.svg";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import { Company } from "../../types/Company";
import { getTranslation } from "../../utils/getTranslation";
import s from "./Companies.module.css";
import CreateCompany from "./CreateCompany/CreateCompany";
import SelectedCompany from "./SelectedCompany/SelectedCompany";

const Companies = () => {
  const token = localStorage.getItem("token");
  const [companiesData, setCompaniesData] = useState<Company[] | null>(null);
  const [page, setPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [createCompanyModal, setCreateCompanyModal] = useState(false);
  const [searchCompany, setSearchCompany] = useState("");
  const [actionLaoders, setActionLaoders] = useState({ loadCompanies: false });
  const [reloadCompanyTriger, setReloadCompanyTriger] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      setActionLaoders((prev) => ({ ...prev, loadCompanies: true }));

      const response = await CompanyApi.getAllCompanies(
        token,
        page,
        rowsPerPage,
        searchCompany
      );
      setActionLaoders((prev) => ({ ...prev, loadCompanies: false }));
      if (response.status && response.companies) {
        setCompaniesData(response.companies);
        setTotalAmount(response.totalAmount);
      }
    };
    makeAsync();
  }, [token, page, rowsPerPage, reloadCompanyTriger, searchCompany]);

  const selectCompanyHandler = (companyId: string) => {
    if (!companiesData) return;
    const selectCompany = companiesData.filter(
      (item) => item._id === companyId
    )[0];
    setSelectedCompany(selectCompany);
  };

  return (
    <div className={s.container}>
      <div
        className={`${s.allCompaniesContainer} ${
          selectedCompany ? s.allCompaniesHidden : s.allCompaniesVisible
        }`}
      >
        <div className={s.headBlock}>
          <div className={s.searchBlock}>
            <Input
              inputValue={searchCompany}
              isVisible
              onChangeInput={(value) => setSearchCompany(value)}
              required
              placeholder="Search company by title..."
              isSearchinput
            />
          </div>
          <StandartButton
            buttonTitle="Create Company"
            action={() => setCreateCompanyModal(true)}
          />
        </div>
        {actionLaoders.loadCompanies ? (
          <div className={s.mainLoaderContainer}>
            <Loader size={100} />
          </div>
        ) : (
          <div className={s.tableBlock}>
            {companiesData && companiesData.length ? (
              <CompaniesTable
                count={totalAmount}
                page={page}
                rowsPerPage={rowsPerPage}
                selectCompaniesHandler={selectCompanyHandler}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rows={companiesData.map((item) => ({
                  _id: item._id,
                  name: item.name,
                  owner: {
                    _id: item.owner._id,
                    name: getTranslation(item.owner.name),
                    surname: getTranslation(item.owner.surname),
                  },
                  clientsTotal: item.clients.length,
                  specialistsTotal: item.specialists.length,
                  programTotal: item.projects.length,
                }))}
              />
            ) : null}
          </div>
        )}
      </div>
      <div
        className={`${s.selectedCompanyInfo} ${
          selectedCompany ? s.companyInfoVisible : s.companyInfoHidden
        }`}
      >
        {selectedCompany && (
          <div className={s.companyInfoContentBlock}>
            <div className={s.locationBlock}>
              <div
                className={s.backBtn}
                role="button"
                onClick={() => setSelectedCompany(null)}
              >
                <ChevronLeftIcon className={s.backIcon} />
                <span>Back</span>
              </div>
              <div className={s.locationTitle}>
                <span>companies</span>
                {`>`}
                <span>{selectedCompany.name}</span>
              </div>
            </div>
            <div className={s.contentBlock}>
              <SelectedCompany
                selectedCompanyData={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                setCompaniesData={setCompaniesData}
              />
            </div>
          </div>
        )}
      </div>
      <ModalWindow
        setIsOpen={setCreateCompanyModal}
        isOpen={createCompanyModal}
        maxWidth="fit-content"
      >
        <CreateCompany
          setModalVisible={setCreateCompanyModal}
          setPage={setPage}
          setReloadCompanyTriger={setReloadCompanyTriger}
        />
      </ModalWindow>
    </div>
  );
};

export default Companies;
