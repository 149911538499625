import { getDocument } from "pdfjs-dist";

export const getPdfThumbnail = async (pdfFileUrl: string) => {
  const pdf = await getDocument(pdfFileUrl).promise;
  const page = await pdf.getPage(1); // Получаем первую страницу

  const viewport = page.getViewport({ scale: 1 }); // Можешь изменить масштаб
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Canvas context not available"); // Обрабатываем случай, если контекст не получен
  }
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
  return canvas.toDataURL(); // Возвращаем изображение в формате base64
};
