export const getTranslation = (
  translationsArray: { text: string; language: string }[] | undefined,
  selectedLang: string = "en"
): string => {
  if (!translationsArray || !selectedLang) return "";

  // Ищем перевод для выбранного языка
  const selectedTranslation = translationsArray.find(
    (el) => el.language === selectedLang
  );

  // Если найден перевод для выбранного языка и он не равен undefined, возвращаем его
  if (selectedTranslation?.text !== undefined) {
    return selectedTranslation.text;
  }

  // Если перевод для выбранного языка не найден, ищем перевод на английском
  const englishTranslation = translationsArray.find(
    (el) => el.language === "en"
  );

  if (englishTranslation?.text !== undefined) {
    return englishTranslation.text;
  }

  // Если ни один из вышеуказанных переводов не найден, возвращаем текст первого элемента
  return translationsArray[0]?.text || "";
};
