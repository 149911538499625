import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ProjectApi from "../../../../../api/projectApi";
import SpecialistApi from "../../../../../api/specialistsApi";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect/AutocompleteSelect";
import Input from "../../../../../components/Input/Input";
import Loader from "../../../../../components/Loader/Loader";
import MuiSwitch from "../../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import { Company, Project } from "../../../../../types/Company";
import {
  ProjectsAvailableSessionTypes,
  TypeNewProject,
} from "../../../../../types/Projects";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import s from "./CreateProgram.module.css";

const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;

type DropDownMenuData = { lable: string; value: string; parentTag?: string };
type TagsData = {
  level1: DropDownMenuData[];
  level2: DropDownMenuData[];
  level3: DropDownMenuData[];
};

type CreateProgramProps = {
  selectedCompany: Company;
  setProgramModalVisible: Dispatch<SetStateAction<boolean>>;
  setProgramsData: Dispatch<SetStateAction<Project[] | null>>;
  isUpdateProgram?: boolean;
  programData?: Project;
};

const nextWeek = new Date(new Date().setDate(new Date().getDate() + 7));

const initialNewProgramData: TypeNewProject = {
  title: "",
  description: "",
  isPrivate: true,
  creationDate: new Date(),
  endDate: nextWeek,
  clientLimit: 1,
  specialistLimit: 1,
  treeQuizTagsToSkip: [],
  availableSessionTypes: [
    {
      type: "" as ProjectsAvailableSessionTypes,
      amount: 1,
      unlimited: true,
    },
  ],
};

const CreateProgram = ({
  selectedCompany,
  setProgramModalVisible,
  setProgramsData,
  isUpdateProgram,
  programData,
}: CreateProgramProps) => {
  const token = localStorage.getItem("token");
  const [newProgramData, setNewProgramData] = useState(initialNewProgramData);
  const [video, setVideo] = useState<File | undefined>();
  const [loader, setLoader] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [tags, setTags] = useState<TagsData>({
    level1: [],
    level2: [],
    level3: [],
  });
  const [selectedTagsToSkip, setSelectedTagsToSkip] = useState<{
    level1: string[];
    level2: string[];
    level3: string[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });

  const gatTags = async () => {
    if (!token) return;
    const payload = {};
    const response = await SpecialistApi.getSpecialistTags(token, payload);
    if (response.status && response.tags) {
      const tagsDataLevel1 = response.tags
        .filter((el) => el.level === 1)
        .map((item) => ({
          lable: item.labels.find((el) => el.language === "en")!.text,
          value: item._id,
        }));

      const tagsDataLevel2 = response.tags
        .filter((el) => el.level === 2)
        .map((item) => ({
          lable: item.labels.find((el) => el.language === "en")!.text,
          value: item._id,
        }));

      const tagsDataLevel3 = response.tags
        .filter((el) => el.level === 3)
        .map((item) => ({
          lable: item.labels.find((el) => el.language === "en")!.text,
          value: item._id,
        }));

      setTags({
        level1: tagsDataLevel1,
        level2: tagsDataLevel2,
        level3: tagsDataLevel3,
      });
    }
  };
  useEffect(() => {
    gatTags();
  }, []);

  useEffect(() => {
    if (!programData) return;
    const currentDiscount =
      programData.availableSessionTypes![0].type ===
      ProjectsAvailableSessionTypes.SESSION
        ? programData.sessionDiscount
        : programData.packageDiscount;
    setDiscount(currentDiscount);
    if (
      programData.treeQuizTagsToSkip &&
      programData.treeQuizTagsToSkip.length
    ) {
      setSelectedTagsToSkip({
        level1: programData.treeQuizTagsToSkip
          .filter((el) => el.level === 1)
          .map((item) => item._id),
        level2: programData.treeQuizTagsToSkip
          .filter((el) => el.level === 2)
          .map((item) => item._id),
        level3: programData.treeQuizTagsToSkip
          .filter((el) => el.level === 3)
          .map((item) => item._id),
      });
    }
  }, [programData]);

  const projectTypeData = [
    {
      value: ProjectsAvailableSessionTypes.SESSION,
      lable: "Sessions with different specialists",
    },
    {
      value: ProjectsAvailableSessionTypes.PACKAGE,
      lable: "Sessions with one specialist",
    },
  ];

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setVideo(file);
    } else {
      setVideo(undefined);
    }
  };

  useEffect(() => {
    if (!isUpdateProgram || !programData) return;

    const currentProgramData = {
      title: programData.title,
      description: programData.description,
      isPrivate: programData.isPrivate,
      creationDate: new Date(programData.creationDate!),
      endDate: new Date(programData.endDate!),
      clientLimit: programData.clientLimit,
      specialistLimit: programData.specialistLimit,
      sessionDiscount: programData.sessionDiscount,
      availableSessionTypes: programData.availableSessionTypes,
    };
    setNewProgramData(currentProgramData);
  }, [programData, isUpdateProgram]);

  const createProject = async () => {
    try {
      if (
        !newProgramData.clientLimit ||
        !newProgramData.clientLimit ||
        !newProgramData.title.length ||
        !newProgramData.description.length ||
        !newProgramData.availableSessionTypes ||
        !newProgramData.availableSessionTypes[0].type.length
      ) {
        return Notify("Fill in all the fields");
      }

      if (token && !isUpdateProgram) {
        const payload = {
          companyId: selectedCompany._id,
          newProject: {
            ...newProgramData,
            treeQuizTagsToSkip: [
              ...selectedTagsToSkip.level1,
              ...selectedTagsToSkip.level2,
              ...selectedTagsToSkip.level3,
            ],
            sessionDiscount:
              newProgramData.availableSessionTypes[0].type ===
              ProjectsAvailableSessionTypes.SESSION
                ? discount
                : 0,
            packageDiscount:
              newProgramData.availableSessionTypes[0].type ===
              ProjectsAvailableSessionTypes.PACKAGE
                ? discount
                : 0,
          },
        };

        setLoader(true);

        const response = await ProjectApi.createProject(token, payload);

        if (response.status && response.project) {
          setProgramsData((prev) =>
            prev ? [...prev, response.project] : [response.project]
          );

          if (video) {
            const formData = new FormData();
            formData.append("video", video);
            formData.append("projectId", response.project._id);

            const videoResponse = await ProjectApi.uploadVideoToProject(
              token,
              formData
            );

            if (videoResponse.status) {
              SuccesNotify("Project has been created");
            } else if (videoResponse.message) {
              Notify(videoResponse.message);
            }
          } else {
            SuccesNotify("Project has been created");
          }
          setProgramModalVisible(false);
        }
      }
    } catch (error) {
      console.error("Error creating project:", error);
      Notify("An error occurred while creating the project");
    } finally {
      setLoader(false);
    }
  };

  const formateDateForProgram = (value: Date) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const day = value.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const updateProject = async () => {
    if (!token || !programData) return;
    if (
      !newProgramData.clientLimit ||
      !newProgramData.clientLimit ||
      !newProgramData.title.length ||
      !newProgramData.description.length ||
      !newProgramData.availableSessionTypes ||
      !newProgramData.availableSessionTypes[0].type.length
    ) {
      return Notify("Fill in all the fields");
    }
    const updateData = {
      _id: programData._id,
      ...newProgramData,
      treeQuizTagsToSkip: [
        ...selectedTagsToSkip.level1,
        ...selectedTagsToSkip.level2,
        ...selectedTagsToSkip.level3,
      ],
      sessionDiscount:
        newProgramData.availableSessionTypes[0].type ===
        ProjectsAvailableSessionTypes.SESSION
          ? discount
          : 0,
      packageDiscount:
        newProgramData.availableSessionTypes[0].type ===
        ProjectsAvailableSessionTypes.PACKAGE
          ? discount
          : 0,
    };
    const response = await ProjectApi.update(token, updateData);
    if (video) {
      const formData = new FormData();
      formData.append("video", video);
      formData.append("projectId", programData._id);

      const videoResponse = await ProjectApi.uploadVideoToProject(
        token,
        formData
      );

      if (videoResponse.status) {
        SuccesNotify("Video has been updated");
      } else if (videoResponse.message) {
        Notify(videoResponse.message);
      }
    }
    if (response.status) {
      setProgramsData((prev) =>
        prev
          ? prev.map((item) => {
              if (item._id === programData._id) {
                return response.project;
              }
              return item;
            })
          : prev
      );
      SuccesNotify("Project has been updated");
      setProgramModalVisible(false);
    }
  };

  if (loader) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={50} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.titleBlock}>
        <h2 className={s.headTitle}>
          {isUpdateProgram ? "Update Program" : "Create Program"}
        </h2>
        <Input
          inputValue={newProgramData.title}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({ ...prev, title: value }))
          }
          isVisible
          required
          lable="Program title"
        />
      </div>
      <div className={s.videoSection}>
        <span className={s.inputTitle}>Intro video</span>
        {video || (programData && programData.video) ? (
          <div className={s.videoBlock}>
            <video
              src={
                video
                  ? URL.createObjectURL(video)
                  : `${connectionString}projects/getProjectVideoStream/${
                      programData!.video
                    }`
              }
              className={s.video}
              controls
            ></video>
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="video/*"
                onChange={(e) => handleVideoChange(e)}
              />
              <div className={s.uploadVideoBtn} role="button">
                Change video
              </div>
            </label>
          </div>
        ) : (
          <div>
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="video/*"
                onChange={(e) => handleVideoChange(e)}
              />
              <div className={s.uploadVideoBtn} role="button">
                Upload video
              </div>
            </label>
          </div>
        )}
      </div>
      <div className={s.descriptionBlock}>
        <span className={s.inputTitle}>
          Enter the description of the program
        </span>
        <textarea
          name=""
          id=""
          cols={30}
          rows={7}
          value={newProgramData.description}
          className={s.textArea}
          onChange={(event) =>
            setNewProgramData((prev) => ({
              ...prev,
              description: event.target.value,
            }))
          }
        ></textarea>
      </div>
      <div className={s.inputsBlock}>
        <div className={s.privateBlock}>
          <MuiSwitch
            lable="Is private program?"
            lablePlacement="start"
            checked={newProgramData.isPrivate}
            setChecked={(value: boolean) =>
              setNewProgramData((prev) => ({ ...prev, isPrivate: value }))
            }
          />
        </div>
        <div className={s.endDateBlock}>
          <span className={s.inputTitle}>Program end date</span>
          <input
            type="date"
            value={formateDateForProgram(newProgramData.endDate!)}
            onChange={(e) =>
              setNewProgramData((prev) => ({
                ...prev,
                endDate: new Date(e.target.value),
              }))
            }
          />
        </div>
      </div>
      <div className={s.projectTypeBlock}>
        <MultipleSelect
          data={projectTypeData}
          multiplie={false}
          setValue={(value) =>
            setNewProgramData((prev) => ({
              ...prev,
              availableSessionTypes: [
                {
                  ...prev.availableSessionTypes![0],
                  type: value as ProjectsAvailableSessionTypes,
                },
              ],
            }))
          }
          value={newProgramData.availableSessionTypes![0].type}
          lable="Project type"
          width="33.33%"
        />
        <Input
          inputValue={newProgramData.availableSessionTypes![0].amount.toString()}
          type="number"
          onChangeInput={(value) => {
            const numericValue = value === "" ? 0 : Number(value);
            if (Number.isInteger(numericValue) && numericValue >= 0) {
              setNewProgramData((prev) => ({
                ...prev,
                availableSessionTypes: [
                  {
                    ...prev.availableSessionTypes![0],
                    amount: numericValue,
                  },
                ],
              }));
            }
          }}
          isVisible
          required
          minNumber={0}
          maxNumber={9999}
          lable={"Limit"}
          disabled={newProgramData.availableSessionTypes![0].unlimited}
          width="33.33%"
        />
        <MuiSwitch
          lable="Unlimited"
          lablePlacement="start"
          checked={newProgramData.availableSessionTypes![0].unlimited}
          setChecked={(value: boolean) =>
            setNewProgramData((prev) => ({
              ...prev,
              availableSessionTypes: [
                {
                  ...prev.availableSessionTypes![0],
                  amount: value ? 0 : prev.availableSessionTypes![0].amount,
                  unlimited: value,
                },
              ],
            }))
          }
        />
      </div>
      <div className={s.inputsBlock}>
        <Input
          inputValue={newProgramData.clientLimit.toString()}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({
              ...prev,
              clientLimit: Number(value),
            }))
          }
          isVisible
          required
          lable="Clients limit"
        />
        <Input
          inputValue={newProgramData.specialistLimit.toString()}
          onChangeInput={(value) => {
            const numericValue = value === "" ? 0 : Number(value);
            if (Number.isInteger(numericValue) && numericValue >= 0) {
              setNewProgramData((prev) => ({
                ...prev,
                specialistLimit: numericValue,
              }));
            }
          }}
          isVisible
          required
          lable="Specialists limit"
        />
        <Input
          inputValue={discount.toString()}
          onChangeInput={(value) => {
            const numericValue = value === "" ? 0 : Number(value);
            if (Number.isInteger(numericValue) && numericValue >= 0) {
              setDiscount(numericValue);
            }
          }}
          isVisible
          required
          lable="Discount(%)"
        />
      </div>
      <div className={s.tagsSkipBlock}>
        <span
          className={s.inputTitle}
          style={{ marginBottom: "15px", display: "block" }}
        >
          Tree quiz tags for skip
        </span>
        <div className={s.inputsBlock}>
          {tags.level1 && (
            <AutocompleteSelect
              data={tags.level1}
              setValue={(value) =>
                setSelectedTagsToSkip((prev) => ({
                  ...prev,
                  level1: value as string[],
                }))
              }
              value={selectedTagsToSkip.level1}
              lable="Direction"
            />
          )}
          {tags.level3 && (
            <AutocompleteSelect
              data={tags.level2}
              setValue={(value) =>
                setSelectedTagsToSkip((prev) => ({
                  ...prev,
                  level2: value as string[],
                }))
              }
              value={selectedTagsToSkip.level2}
              lable="Topic"
            />
          )}
          {tags.level3 && (
            <AutocompleteSelect
              data={tags.level3}
              setValue={(value) =>
                setSelectedTagsToSkip((prev) => ({
                  ...prev,
                  level3: value as string[],
                }))
              }
              value={selectedTagsToSkip.level3}
              lable="Result"
            />
          )}
          {/*    <MultipleSelect
            data={tags.level3}
            multiplie
            setValue={(value) =>
              setSelectedTagsToSkip((prev) => ({
                ...prev,
                level3: value as string[],
              }))
            }
            value={selectedTagsToSkip.level3}
            lable="Result"
          /> */}
        </div>
      </div>
      <div className={s.actionBlock}>
        <StandartButton
          action={isUpdateProgram ? updateProject : createProject}
          buttonTitle={isUpdateProgram ? "Update" : "Create"}
          width="200px"
        />
      </div>
    </div>
  );
};

export default CreateProgram;
