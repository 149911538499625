import { useContext, useState } from "react";
import UsersApi from "../../api/usersApi";
import { ContextProvider } from "../../contextProvider";
import s from "./Authorization.module.css";

const Authorization = () => {
  const { setIsAuth } = useContext(ContextProvider);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const authHandler = async () => {
    if (username === "" || password === "") return;
    const response = await UsersApi.auth(username, password, "en");
    if (response.status && !response.userData?.roles?.includes("ADMIN")) {
      return setError("You do not have access to the admin panel");
    }
    if (!response.status && response.message) setError(response.message);
    if (response.status && response.token) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("refreshToken", response.refreshToken!);
      setUsername("");
      setPassword("");
      setIsAuth(true);
    }
  };

  return (
    <div className={s.body}>
      <div className={s.loginBox}>
        <h2>Login</h2>
        <form>
          <div className={s.userBox}>
            <input type="text" onChange={(e) => setUsername(e.target.value)} />
            <label>Email or phone number</label>
          </div>
          <div className={s.userBox}>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password</label>
          </div>
          <div className={s.loginBtnBlock}>
            <div className={s.loginNav} onClick={authHandler}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Login
            </div>
          </div>
        </form>
        <p className={s.errorMessage}>{error}</p>
      </div>
    </div>
  );
};

export default Authorization;
