import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CompanyApi from "../../../../api/companyApi";
import UsersApi from "../../../../api/usersApi";
import { ReactComponent as MagnifierIcon } from "../../../../assets/Specialists/magnifier.svg";
import Input from "../../../../components/Input/Input";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import {
  Company,
  Project,
  TypeCompanyEmployee,
} from "../../../../types/Company";
import { getTranslation } from "../../../../utils/getTranslation";
import AddSpecialistsToCompany from "../AddSpecialistsToCompany/AddSpecialistsToCompany";
import UpdateCompany from "../UpdateCompany/UpdateCompany";
import EmployerCard from "./EmployerCard/EmployerCard";
import s from "./Participants.module.css";

type ParticipantsProps = {
  selectedCompany: Company;
  setSelectedCompany: Dispatch<SetStateAction<Company | null>>;
  setCompaniesData: Dispatch<SetStateAction<Company[] | null>>;
};

const Participants = ({
  selectedCompany,
  setSelectedCompany,
  setCompaniesData,
}: ParticipantsProps) => {
  const token = localStorage.getItem("token");
  const companyEmployers = useRef<TypeCompanyEmployee[] | null>([]);
  const [search, setSearch] = useState("");
  const [companyProjects, setCompanyProjects] = useState<Project[] | null>(
    null
  );
  const [filteredEmployers, setFilteredEmployers] = useState<
    TypeCompanyEmployee[] | null
  >([]);
  const [projectFilterValue, setProjectFilterValue] = useState<
    string | string[]
  >([]);
  const [roleFilterValue, setRoleFilterValue] = useState<string | string[]>([]);
  const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});
  const [changeOwnerModal, setChangeOwnerModal] = useState(false);
  const [addSpecialistModal, setAddSpecialistModal] = useState(false);

  const roleData = [
    { lable: "employee", value: "employee" },
    { lable: "manager", value: "manager" },
    { lable: "owner", value: "owner" },
    { lable: "specialist", value: "specialist" },
  ];
  useEffect(() => {
    if (token) {
      const makeAsync = async () => {
        const companyEmployersResponse =
          await CompanyApi.getCompanyParticipants(token, selectedCompany._id);

        if (companyEmployersResponse.status) {
          companyEmployers.current = companyEmployersResponse.participants;
          setFilteredEmployers(companyEmployersResponse.participants);
        }
        const projectResponse = await CompanyApi.getCompanyProjects(
          token,
          selectedCompany._id
        );
        if (projectResponse.status)
          setCompanyProjects(projectResponse.projects);
      };

      makeAsync();
    }
  }, [selectedCompany]);
  useEffect(() => {
    setFilteredEmployers(companyEmployers.current);
    const makeAsync = async () => {
      if (
        token &&
        companyEmployers.current &&
        companyEmployers.current.length > 0
      ) {
        const promises = companyEmployers.current.map((employer) =>
          UsersApi.getUserPublicAvatarAndName(employer.user._id)
        );
        const responses = await Promise.all(promises);
        const avatarsData = responses.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });

        setAvatars(avatarsData);
      }
    };
    makeAsync();
  }, [companyEmployers.current]);

  useEffect(() => {
    if (companyEmployers.current) {
      let filteredEmployees = companyEmployers.current;

      if (roleFilterValue.length) {
        filteredEmployees = filteredEmployees.filter((employee) =>
          roleFilterValue.includes(employee.role.value)
        );
      }

      if (projectFilterValue.length && companyProjects) {
        filteredEmployees = filteredEmployees.filter((employee) => {
          return employee.user.projects.some((project) =>
            projectFilterValue.includes(project)
          );
        });
      }

      setFilteredEmployers(filteredEmployees);
    }
  }, [roleFilterValue, projectFilterValue, companyProjects]);

  const filterEmployers = (value: string) => {
    setSearch(value);
    if (companyEmployers.current) {
      const filteredContacts = companyEmployers.current.filter((employer) => {
        const searchTerm = value.toLowerCase();
        const fullname = `${getTranslation(
          employer.user.name
        )} ${getTranslation(employer.user.surname)}`.toLowerCase();
        return fullname.includes(searchTerm);
      });
      setFilteredEmployers(filteredContacts);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.mainContentContainer}>
        <div className={s.employersTableContainer}>
          <div className={s.headBlock}>
            <div className={s.filterBlock}>
              <div className={s.searchInputBlock}>
                <Input
                  inputValue={search}
                  onChangeInput={(value) => filterEmployers(value)}
                  isVisible
                  required
                  placeholder={"Search..."}
                />
                <MagnifierIcon className={s.magnifierIcon} />
              </div>
              <div className={s.selectFilterBlock}>
                <span className={s.filterTitle}>Filter by</span>
                <div className={s.selectInputBlock}>
                  <div className={s.multiSelectBlock}>
                    <MultipleSelect
                      data={
                        companyProjects
                          ? companyProjects.map((item) => ({
                              value: item.title,
                              lable: item.title,
                            }))
                          : []
                      }
                      multiplie={true}
                      value={projectFilterValue}
                      setValue={(value) => setProjectFilterValue(value)}
                      lable={"Program"}
                      checkBox={true}
                    />
                  </div>
                  <div className={s.multiSelectBlock}>
                    <MultipleSelect
                      data={roleData}
                      multiplie={true}
                      value={roleFilterValue}
                      setValue={(value) => setRoleFilterValue(value)}
                      lable={"Role"}
                      checkBox={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={s.actionBlock}>
              <StandartButton
                action={() => setChangeOwnerModal(true)}
                buttonTitle="Update company"
                height="40px"
              />
              <StandartButton
                action={() => setAddSpecialistModal(true)}
                buttonTitle="Invite specialists"
                height="40px"
              />
            </div>
          </div>
          <div className={s.lableTableBlock}>
            <span className={s.nameTableLable}>Name</span>
            <span className={s.roleTableLable}>Role</span>
            <span className={s.programTableLable}>Programs</span>
            <span className={s.emailTableLable}>Email</span>
          </div>
          <div className={s.tableBlock}>
            {filteredEmployers &&
              filteredEmployers.map((item, index) => (
                <div
                  key={index}
                  className={s.employeeCardContainer}
                  style={{
                    borderRadius:
                      index === filteredEmployers.length - 1
                        ? "0 0 20px 20px"
                        : "0",
                    backgroundColor:
                      (index + 1) % 2 === 0
                        ? "rgba(63, 176, 130, 0.1)"
                        : "rgb(46, 46, 46)",
                  }}
                >
                  <EmployerCard
                    companyId={selectedCompany._id}
                    userId={item.user._id}
                    email={item.user.email}
                    fullName={
                      getTranslation(item.user.name) +
                      " " +
                      getTranslation(item.user.surname)
                    }
                    program={item.user.projects}
                    role={item.role.value}
                    avatar={avatars[item.user._id]}
                    setFilteredEmployers={setFilteredEmployers}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={changeOwnerModal}
        setIsOpen={setChangeOwnerModal}
        maxWidth="fit-content"
      >
        <UpdateCompany
          selectedCompany={selectedCompany}
          setUpdateModalVisible={setChangeOwnerModal}
          setSelectedCompany={setSelectedCompany}
          setCompaniesData={setCompaniesData}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={addSpecialistModal}
        setIsOpen={setAddSpecialistModal}
        maxWidth="fit-content"
      >
        <AddSpecialistsToCompany
          selectedCompany={selectedCompany}
          setModalVisible={setAddSpecialistModal}
        />
      </ModalWindow>
    </div>
  );
};

export default Participants;
