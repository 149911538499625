import { Dispatch, SetStateAction, useEffect, useState } from "react";

import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as PlusIcon } from "../../../../assets/Certificates/add.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/General/close.svg";
import Input from "../../../../components/Input/Input";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { siteLangs } from "../../../../constans/siteLangs";
import {
  Education,
  EducationAccreditation,
  Federation,
  Qualification,
} from "../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../types/TypeUsers";
import { getTranslation } from "../../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import { areAllTextFieldsFilled } from "../../../../utils/validations";
import s from "../AddCertificatesModal.module.css";

type FederationsCertificates = {
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  specializationsData: { lable: string; value: string }[];
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
  educationToUpdate?: Education;
  coursesToUpdate?: CoursesToUpdate;
};

type CoursesToUpdate = {
  accreditations: (EducationAccreditation | undefined)[];
  _id: string;
  text: {
    language: string;
    text: string;
  }[];
  isShown: boolean;
  education: Education;
  deleted: boolean;
}[];

type NewEducation = {
  newEducation: {
    educationText: { text: string; language: string }[];
    newCoursesAndAccr: {
      courseText: { text: string; language: string }[];
      accreditationText: { text: string; language: string }[][];
    }[];
    prevCourseAndAccr?: CoursesToUpdate;
    specialization: string | undefined;
    isShown: boolean;
  };
};

type NewAccrForPrevCourse = {
  courseId: string;
  text: {
    text: string;
    language: string;
  }[];
};

const emptyMultiLangStrings = [
  { text: "", language: "en" },
  { text: "", language: "ru" },
  { text: "", language: "uk" },
];

const initialEducation: NewEducation = {
  newEducation: {
    educationText: emptyMultiLangStrings,
    newCoursesAndAccr: [
      {
        courseText: emptyMultiLangStrings,
        accreditationText: [emptyMultiLangStrings],
      },
    ],
    prevCourseAndAccr: undefined,
    specialization: undefined,
    isShown: true,
  },
};

const EducationsCertificates = ({
  setActionLoaders,
  setModalOpen,
  specializationsData,
  setCertificatesData,
  educationToUpdate,
  coursesToUpdate,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const [selecLangForText, setSelecLangForText] = useState("en");
  const [addEducation, setAddEducation] =
    useState<NewEducation>(initialEducation);
  const [newAccrForPrevCourses, setNewAccrForPrevCourses] = useState<
    NewAccrForPrevCourse[] | undefined
  >();

  useEffect(() => {
    function filterByLanguage(
      array1: { text: string; language: string }[],
      array2: { text: string; language: string }[]
    ): { text: string; language: string }[] {
      // Получаем список всех языков из второго массива
      const languagesToRemove = new Set(array2.map((item) => item.language));

      // Фильтруем первый массив, исключая элементы с совпадающим language
      return array1.filter((item) => !languagesToRemove.has(item.language));
    }
    if (!educationToUpdate) return;

    const newLang = filterByLanguage(
      emptyMultiLangStrings,
      educationToUpdate.text
    );
    setAddEducation((prev) => ({
      ...prev,
      newEducation: {
        educationText: [...educationToUpdate.text, ...newLang],
        newCoursesAndAccr: [
          /*   {
            courseText: emptyMultiLangStrings,
            accreditationText: [emptyMultiLangStrings],
          }, */
        ],
        prevCourseAndAccr: coursesToUpdate,
        specialization: educationToUpdate.specialization._id,
        isShown: educationToUpdate.isShown,
      },
    }));
  }, [educationToUpdate]);

  const createEducation = async () => {
    if (!token) return;
    const payload = {
      educationText: addEducation.newEducation.educationText,
      specialization: addEducation.newEducation.specialization!,
      isShown: addEducation.newEducation.isShown
    };
    const response = await SpecialistApi.createEducation(token, payload);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.education) {
      setCertificatesData((prev) => ({
        ...prev,
        educations: [response.education!, ...prev.educations],
      }));
    }
    return { status: response.status, educationId: response.education?._id };
  };

  const updateEducationCourse = async (updateData: TypeUpdatedUserInfo) => {
    if (!educationToUpdate || !token) return;
    const response = await SpecialistApi.updateEducationCourse(
      token,
      updateData
    );
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return response;
  };

  const updateEducationAccreditation = async (
    updateData: TypeUpdatedUserInfo
  ) => {
    if (!educationToUpdate || !token) return;
    const response = await SpecialistApi.updateEducationAccreditation(
      token,
      updateData
    );
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return response;
  };

  const updateCoursesAndAccr = async () => {
    if (!addEducation.newEducation.prevCourseAndAccr) return;
    const promisesCourses = addEducation.newEducation.prevCourseAndAccr.map(
      async (item) => {
        const updateDataCourse = {
          _id: item._id,
          delete: item.deleted,
          text: item.text,
        };
        const courseResponse = await updateEducationCourse(updateDataCourse);
        if (!item.accreditations) return courseResponse?.status;
        const promisesAccr = item.accreditations.map(async (accrItem) => {
          const updateDataAccr = {
            _id: accrItem!._id,
            text: accrItem!.text,
          };
          const accrResponse = await updateEducationAccreditation(
            updateDataAccr
          );
          return accrResponse!.status;
        });
        const accrResults = await Promise.all(promisesAccr);
        return [...accrResults, courseResponse!.status!];
      }
    );
    const results = await Promise.all(promisesCourses);

    return results as boolean[];
  };

  const updateEducation = async () => {
    if (!token || !educationToUpdate) return;
    const educationData = addEducation.newEducation;
    if (
      educationData.newCoursesAndAccr &&
      educationData.newCoursesAndAccr.length
    ) {
      await createCoursesAndAccr(educationToUpdate._id);
    }
    if (newAccrForPrevCourses && newAccrForPrevCourses.length) {
      const newAccrForPrevCoursesPromises = newAccrForPrevCourses.map(
        async (item) => {
          const response = await createEducationAccreditation(
            item.courseId,
            item.text
          );
          return response;
        }
      );
      await Promise.all(newAccrForPrevCoursesPromises);
    }
    await updateCoursesAndAccr();
    const updateData = {
      _id: educationToUpdate._id,
      text: educationData.educationText,
      specialization: educationData.specialization,
      isShown: educationData.isShown,
    };

    const updateEducationResponse = await SpecialistApi.updateEducation(
      token,
      updateData
    );
    if (updateEducationResponse.status && updateEducationResponse.education) {
      SuccesNotify("Education has been updated");
      setAddEducation(initialEducation);
      setModalOpen(false);
    }
  };

  const createEducationCourse = async (
    educationId: string,
    courseText: { text: string; language: string }[],
    isShown: boolean
  ) => {
    if (!token) return;
    const payload = {
      courseText: courseText,
      educationId: educationId,
      isShown: isShown,
    };
    const response = await SpecialistApi.createEducationCourse(token, payload);

    if (response.status && response.course) {
      return response.course._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };
  const createEducationAccreditation = async (
    courseId: string,
    accreditationText: { text: string; language: string }[]
  ) => {
    if (!token) return;
    const payload = {
      accreditationText: accreditationText,
      courseId: courseId,
      isShown: true,
    };
    const response = await SpecialistApi.createEducationAccreditation(
      token,
      payload
    );

    if (response.status && response.accreditation) {
      return response.accreditation._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };

  const createCoursesAndAccr = async (educationId: string) => {
    const promises = addEducation.newEducation.newCoursesAndAccr.map(
      async (item) => {
        const courseResponse = await createEducationCourse(
          educationId,
          item.courseText,
          true
        );

        if (
          courseResponse &&
          areAllTextFieldsFilled(item.accreditationText.flat())
        ) {
          const accrResponses = item.accreditationText.map(async (accrItem) => {
            await createEducationAccreditation(courseResponse, accrItem);
          });
          return accrResponses;
        } else if (courseResponse) {
          return true;
        }
        return false;
      }
    );

    // Дождаться выполнения всех промисов и вернуть массив булевых значений
    const results = await Promise.all(promises);
    return results;
  };

  const saveEducation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let educationId: string | undefined = undefined;
    const educationResponse = await createEducation();
    educationId = educationResponse?.educationId;
    if (!educationId) return;
    const coursesAndAccrResponses = await createCoursesAndAccr(educationId);
    if (!coursesAndAccrResponses.includes(false)) {
      SuccesNotify("Education has been added");
      setAddEducation(initialEducation);
      setModalOpen(false);
    }
    setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
  };

  const validationNewCertificate = () => {
    const newEducation = addEducation.newEducation;
    const validateCuorses = newEducation.newCoursesAndAccr.map((item) => {
      return areAllTextFieldsFilled(item.courseText);
    });
    if (
      !areAllTextFieldsFilled(newEducation.educationText) ||
      !newEducation.specialization ||
      validateCuorses.includes(false)
    )
      return false;
    return true;
  };

  return (
    <div
      className={`${s.container} ${s.federationContainer}`}
      style={{ width: "600px" }}
    >
      <div className={s.langsBlock}>
        {siteLangs.map((item, index) => (
          <div
            key={index}
            className={
              selecLangForText === item.value ? s.activeTab : s.notActiveTab
            }
            onClick={() => setSelecLangForText(item.value)}
          >
            {item.lable}
            {selecLangForText === item.value && (
              <span className={s.tabIndicator}></span>
            )}
          </div>
        ))}
      </div>
      <div className={s.newFederationBlock}>
        <Input
          inputValue={
            getTranslation(
              addEducation.newEducation.educationText,
              selecLangForText
            ) ?? ""
          }
          isVisible
          required
          onChangeInput={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              newEducation: {
                ...prev.newEducation,
                educationText: prev.newEducation.educationText.map((item) => {
                  if (item.language === selecLangForText) {
                    return {
                      text: value,
                      language: item.language,
                    };
                  } else return item;
                }),
              },
            }))
          }
          lable={"Education title"}
          margin="0 0 20px 0"
        />
        <div>
          <span className={s.secondLable}>Specialization</span>
          <MultipleSelect
            data={specializationsData}
            multiplie={false}
            setValue={(value) =>
              setAddEducation((prev) => ({
                ...prev,
                newEducation: {
                  ...prev.newEducation,
                  specialization: value as string,
                },
              }))
            }
            value={addEducation.newEducation.specialization ?? ""}
          />
        </div>
        <div>
          <span className={s.secondLable}>Is verified education?</span>
          <MuiSwitch
            checked={addEducation.newEducation.isShown}
            setChecked={(value) =>
              setAddEducation((prev) => ({
                ...prev,
                newEducation: { ...prev.newEducation, isShown: value },
              }))
            }
          />
        </div>
        <div>
          <span className={s.secondLable}>Courses and Accreditation</span>
          <div className={s.courseAccrListBlock}>
            {addEducation.newEducation.prevCourseAndAccr &&
              addEducation.newEducation.prevCourseAndAccr
                .filter((el) => !el.deleted)
                .map((item, index) => (
                  <div className={s.courseAccrElement} key={item._id}>
                    <Input
                      inputValue={
                        getTranslation(item.text, selecLangForText) ?? ""
                      }
                      isVisible
                      required
                      onChangeInput={(value) =>
                        setAddEducation((prev) => ({
                          ...prev,
                          newEducation: {
                            ...prev.newEducation,
                            prevCourseAndAccr:
                              prev.newEducation.prevCourseAndAccr!.map(
                                (el, i) => ({
                                  ...el,
                                  text:
                                    index !== i
                                      ? el.text
                                      : el.text.map((textItem) => {
                                          if (
                                            textItem.language ===
                                            selecLangForText
                                          ) {
                                            return {
                                              text: value,
                                              language: textItem.language,
                                            };
                                          } else return textItem;
                                        }),
                                })
                              ),
                          },
                        }))
                      }
                      lable={"Course title"}
                      margin="0 0 20px 0"
                    />
                    <div className={s.accrList}>
                      {item.accreditations &&
                        item.accreditations
                          .filter((el) => !el!.deleted)
                          .map((accrItem, accrIndex) => (
                            <div>
                              <div className={s.lableAccrBlock}>
                                <span>Accreditation title</span>
                                <span
                                  onClick={() =>
                                    setAddEducation((prev) => ({
                                      ...prev,
                                      newEducation: {
                                        ...prev.newEducation,
                                        prevCourseAndAccr:
                                          prev.newEducation.prevCourseAndAccr!.map(
                                            (courseItem, courseIndex) => {
                                              if (index === courseIndex) {
                                                return {
                                                  ...courseItem!,
                                                  accreditations:
                                                    courseItem.accreditations!.map(
                                                      (
                                                        accrItemToDelete,
                                                        accrIndexToDelete
                                                      ) => {
                                                        if (
                                                          accrIndexToDelete ===
                                                          accrIndex
                                                        ) {
                                                          return {
                                                            ...accrItemToDelete!,
                                                            deleted: true,
                                                          };
                                                        } else
                                                          return accrItemToDelete!;
                                                      }
                                                    ),
                                                };
                                              } else return courseItem;
                                            }
                                          ),
                                      },
                                    }))
                                  }
                                >
                                  <DeleteIcon className={s.deleteAccrIcon} />
                                </span>
                              </div>
                              <Input
                                inputValue={
                                  getTranslation(
                                    accrItem?.text,
                                    selecLangForText
                                  ) ?? ""
                                }
                                isVisible
                                required
                                onChangeInput={(value) => {
                                  const newAccreditations =
                                    item.accreditations.map(
                                      (accrEl, valueAccrIndex) => ({
                                        ...accrEl!,
                                        text:
                                          valueAccrIndex !== accrIndex
                                            ? accrEl!.text
                                            : accrEl!.text.map((textItem) => {
                                                if (
                                                  textItem.language ===
                                                  selecLangForText
                                                ) {
                                                  return {
                                                    text: value,
                                                    language: textItem.language,
                                                  };
                                                } else return textItem;
                                              }),
                                      })
                                    );
                                  setAddEducation((prev) => ({
                                    ...prev,
                                    newEducation: {
                                      ...prev.newEducation,
                                      prevCourseAndAccr:
                                        prev.newEducation.prevCourseAndAccr!.map(
                                          (prevCourses, prevIndex) => {
                                            if (index !== prevIndex)
                                              return prevCourses;
                                            else
                                              return {
                                                ...prevCourses,
                                                accreditations:
                                                  newAccreditations,
                                              };
                                          }
                                        ),
                                    },
                                  }));
                                }}
                                margin="0 0 20px 0"
                              />
                            </div>
                          ))}
                      {newAccrForPrevCourses &&
                        newAccrForPrevCourses
                          .filter((el) => el.courseId === item._id)
                          .map((accrItem, accrIndex) => (
                            <div>
                              <div className={s.lableAccrBlock}>
                                <span>Accreditation title</span>
                                <span
                                  onClick={() =>
                                    setNewAccrForPrevCourses((prev) =>
                                      prev!.filter((_, i) => accrIndex !== i)
                                    )
                                  }
                                >
                                  <DeleteIcon className={s.deleteAccrIcon} />
                                </span>
                              </div>
                              <Input
                                inputValue={
                                  getTranslation(
                                    accrItem.text,
                                    selecLangForText
                                  ) ?? ""
                                }
                                isVisible
                                required
                                onChangeInput={(value) => {
                                  setNewAccrForPrevCourses((prev) =>
                                    prev!.map((newAccrEl, newAccrIndex) => {
                                      if (newAccrIndex === accrIndex)
                                        return {
                                          ...newAccrEl,
                                          text: newAccrEl.text.map(
                                            (accLangEl) => {
                                              if (
                                                accLangEl.language ===
                                                selecLangForText
                                              )
                                                return {
                                                  ...accLangEl,
                                                  text: value,
                                                };
                                              else return accLangEl;
                                            }
                                          ),
                                        };
                                      else return newAccrEl;
                                    })
                                  );
                                }}
                                margin="0 0 20px 0"
                              />
                            </div>
                          ))}
                    </div>
                    <div className={s.addAccrBtnBlock}>
                      <div
                        className={s.addAccrBtn}
                        onClick={() =>
                          setNewAccrForPrevCourses((prev) =>
                            prev
                              ? [
                                  ...prev,
                                  {
                                    courseId: item._id,
                                    text: emptyMultiLangStrings,
                                  },
                                ]
                              : [
                                  {
                                    courseId: item._id,
                                    text: emptyMultiLangStrings,
                                  },
                                ]
                          )
                        }
                      >
                        <PlusIcon />
                      </div>
                    </div>
                    <div
                      className={s.deleteBtn}
                      onClick={() => {
                        setAddEducation((prev) => ({
                          ...prev,
                          newEducation: {
                            ...prev.newEducation,
                            prevCourseAndAccr:
                              prev.newEducation.prevCourseAndAccr!.map(
                                (courseToDelete, courseToDeleteIndex) => {
                                  if (courseToDeleteIndex === index) {
                                    return { ...courseToDelete, deleted: true };
                                  } else return courseToDelete;
                                }
                              ),
                          },
                        }));
                      }}
                    >
                      <DeleteIcon className={s.closeIcon} />
                    </div>
                  </div>
                ))}
            {addEducation.newEducation.newCoursesAndAccr.map((item, index) => (
              <div className={s.courseAccrElement} key={index}>
                <Input
                  inputValue={
                    getTranslation(item.courseText, selecLangForText) ?? ""
                  }
                  isVisible
                  required
                  onChangeInput={(value) =>
                    setAddEducation((prev) => ({
                      ...prev,
                      newEducation: {
                        ...prev.newEducation,
                        newCoursesAndAccr:
                          prev.newEducation.newCoursesAndAccr.map((el, i) => ({
                            accreditationText: el.accreditationText,
                            courseText:
                              index !== i
                                ? el.courseText
                                : el.courseText.map((textItem) => {
                                    if (
                                      textItem.language === selecLangForText
                                    ) {
                                      return {
                                        text: value,
                                        language: textItem.language,
                                      };
                                    } else return textItem;
                                  }),
                          })),
                      },
                    }))
                  }
                  lable={"Course title"}
                  margin="0 0 20px 0"
                />
                <div className={s.accrList}>
                  {item.accreditationText.map((newAccrItem, newAccrIndex) => (
                    <div>
                      <div className={s.lableAccrBlock}>
                        <span>Accreditation title</span>
                        <span
                          onClick={() =>
                            setAddEducation((prev) => ({
                              ...prev,
                              newEducation: {
                                ...prev.newEducation,
                                newCoursesAndAccr:
                                  prev.newEducation.newCoursesAndAccr.map(
                                    (courseItem, courseIndex) => {
                                      if (index === courseIndex) {
                                        return {
                                          ...courseItem,
                                          accreditationText:
                                            courseItem.accreditationText.filter(
                                              (_, accrIndex) =>
                                                newAccrIndex !== accrIndex
                                            ),
                                        };
                                      } else return courseItem;
                                    }
                                  ),
                              },
                            }))
                          }
                        >
                          <DeleteIcon className={s.deleteAccrIcon} />
                        </span>
                      </div>
                      <Input
                        inputValue={
                          getTranslation(newAccrItem, selecLangForText) ?? ""
                        }
                        isVisible
                        required
                        onChangeInput={(value) =>
                          setAddEducation((prev) => ({
                            ...prev,
                            newEducation: {
                              ...prev.newEducation,
                              newCoursesAndAccr:
                                prev.newEducation.newCoursesAndAccr.map(
                                  (el, i) => ({
                                    courseText: el.courseText,
                                    accreditationText:
                                      index !== i
                                        ? el.accreditationText
                                        : el.accreditationText.map(
                                            (accrArray, accIndex) =>
                                              accIndex !== newAccrIndex
                                                ? accrArray
                                                : accrArray.map((textItem) =>
                                                    textItem.language ===
                                                    selecLangForText
                                                      ? {
                                                          text: value,
                                                          language:
                                                            textItem.language,
                                                        }
                                                      : textItem
                                                  )
                                          ),
                                  })
                                ),
                            },
                          }))
                        }
                        margin="0 0 20px 0"
                      />
                    </div>
                  ))}
                </div>
                <div className={s.addAccrBtnBlock}>
                  <div
                    className={s.addAccrBtn}
                    onClick={() =>
                      setAddEducation((prev) => ({
                        ...prev,
                        newEducation: {
                          ...prev.newEducation,
                          newCoursesAndAccr:
                            prev.newEducation.newCoursesAndAccr.map(
                              (courseItem, courseIndex) => {
                                if (index === courseIndex) {
                                  return {
                                    ...courseItem,
                                    accreditationText: [
                                      ...courseItem.accreditationText,
                                      emptyMultiLangStrings,
                                    ],
                                  };
                                } else return courseItem;
                              }
                            ),
                        },
                      }))
                    }
                  >
                    <PlusIcon />
                  </div>
                </div>
                <div
                  className={s.deleteBtn}
                  onClick={() => {
                    if (
                      addEducation.newEducation.newCoursesAndAccr.length +
                        (addEducation.newEducation.prevCourseAndAccr
                          ? addEducation.newEducation.prevCourseAndAccr.length
                          : 0) ===
                      1
                    )
                      return;

                    setAddEducation((prev) => ({
                      ...prev,
                      newEducation: {
                        ...prev.newEducation,
                        newCoursesAndAccr:
                          prev.newEducation.newCoursesAndAccr.filter(
                            (_, indexToRemove) => index !== indexToRemove
                          ),
                      },
                    }));
                  }}
                >
                  <DeleteIcon className={s.closeIcon} />
                </div>
              </div>
            ))}
            <div
              className={s.addCourseAccrBtn}
              onClick={() =>
                setAddEducation((prev) => ({
                  ...prev,
                  newEducation: {
                    ...prev.newEducation,
                    newCoursesAndAccr: [
                      ...prev.newEducation.newCoursesAndAccr,
                      {
                        courseText: emptyMultiLangStrings,
                        accreditationText: [emptyMultiLangStrings],
                      },
                    ],
                  },
                }))
              }
            >
              <PlusIcon className={s.plusIconCourse} />
            </div>
          </div>
        </div>
      </div>

      <StandartButton
        action={educationToUpdate ? updateEducation : saveEducation}
        buttonTitle={"Save"}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default EducationsCertificates;
