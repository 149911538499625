import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  RefoundSession,
  SessionComment,
  SessionPackage,
} from "../types/TypeSession";
import { TimeRange } from "../types/TypeSpecialist";
import customKy from "./kyExtend";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async getSpecialistsComments(
    userId: string,
    limit: number,
    page: number,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      comments: SessionComment[];
      totalAmount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }

    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistsComments
        }/${userId}/${limit}/${page}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistsSessionsAmount(
    userId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistsSessionsAmount
        }/${userId}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackages(
    token: string,
    specialistUserId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: SessionPackage[] | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistSessionPackages
        }/${specialistUserId}?${params.toString()}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getSessionsWaitingForRefund(
    token: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      sessions?: RefoundSession[];
      amount: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getSessionsWaitingForRefund}/${page}/${limit}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async refundSession(
    token: string,
    payload: {
      userId: string;
      sessionId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${sessions.refundSession}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async payOutSession(
    token: string,
    payload: {
      userId: string;
      sessionId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${sessions.payOutSession}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
