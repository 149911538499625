import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { ReactComponent as CalendarIcon } from "../../assets/General/Calendar.svg";
import s from "./MuiDatePicker.module.css";

type MuiDatePickerProps = {
  value: Dayjs | undefined;
  setValue: (value: Dayjs | null) => void;
};

const MuiDatePicker = ({ value, setValue }: MuiDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={s.datePicker}
        value={value}
        onChange={(value: Dayjs | null) => setValue(value)}
        //@ts-expect-error
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        views={["year", "month", "day"]}
        sx={{
          "& .MuiPickersDay-root .Mui-selected": {
            backgroundColor: "red",
            color: "white",
          },
          "& .MuiPickersDay-root.Mui-selected:hover": {
            backgroundColor: "darkred",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default MuiDatePicker;
