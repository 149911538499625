import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableFooter, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import SubscriptionsAPI from "../../api/subscriptionsApi";
import { Subscription } from "../../types/Subscription";
import MuiSwitch from "../MuiSwitch/MuiSwitch";
import s from "./SubscriptionsTable.module.css";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon color="success" />
        ) : (
          <FirstPageIcon color="success" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight color="success" />
        ) : (
          <KeyboardArrowLeft color="success" />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft color="success" />
        ) : (
          <KeyboardArrowRight color="success" />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon color="success" />
        ) : (
          <LastPageIcon color="success" />
        )}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#313131",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#2E2E2E",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#242424",
    border: 0,
  },
  // hide last border
  "td, th": {
    border: 0,
  },
}));

type SpecialistTableProps = {
  rows: any;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  selectSubscripHandler: (id: string) => void;
  count: number;
  setSubscriptionsData: React.Dispatch<
    React.SetStateAction<Subscription[] | null>
  >;
};
export default function SubscriptionsTable({
  rows,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  selectSubscripHandler,
  count,
  setSubscriptionsData,
}: SpecialistTableProps) {
  const token = localStorage.getItem("token");
  const SubscribersCountCell = ({
    subscriptionId,
  }: {
    subscriptionId: string;
  }) => {
    const [subscribersCount, setSubscribersCount] = React.useState<
      number | "error"
    >(0);

    React.useEffect(() => {
      const fetchSubscribersAmount = async () => {
        if (!token) return;

        const response = await SubscriptionsAPI.getActiveSubscribersAmount(
          token,
          subscriptionId
        );

        if (response.status) {
          setSubscribersCount(response.amount);
        } else {
          setSubscribersCount("error");
        }
      };

      fetchSubscribersAmount();
    }, [subscriptionId]);

    return (
      <StyledTableCell align="left" className={s.specColumn}>
        {subscribersCount}
      </StyledTableCell>
    );
  };

  const displayRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const updateSubStatus = async (status: boolean, subscriptionId: string) => {
    if (!token) return;
    const response = await SubscriptionsAPI.updateSubscription(token, {
      _id: subscriptionId,
      isOn: status,
    });
    if (response.status) {
      setSubscriptionsData((prev) =>
        prev!.map((item) => {
          if (item._id === subscriptionId) {
            return { ...item, isOn: status };
          }
          return item;
        })
      );
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        minWidth: 700,
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "transparent",
      }}
    >
      <Table
        sx={{ minWidth: 700, width: "100%" }}
        aria-label="customized table"
      >
        <TableHead className={s.tableHead}>
          <TableRow>
            <StyledTableCell align="left">Title</StyledTableCell>
            <StyledTableCell align="left">Monthly cost</StyledTableCell>
            <StyledTableCell align="left">Discount</StyledTableCell>
            <StyledTableCell align="left">Total subscribers</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <StyledTableRow
              key={row.id}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => selectSubscripHandler(row._id)}
            >
              <StyledTableCell align="left" className={s.nameColumn}>
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left" className={s.costColumn}>
                {row.costPerMonth}
              </StyledTableCell>
              <StyledTableCell align="left" className={s.emailColumn}>
                {row.discountsForSubscription.map(
                  (
                    item: { days: string; amount: string },
                    index: React.Key | null | undefined
                  ) => (
                    <span key={index}>
                      {item.days + " days " + "-" + item.amount + "%"}
                    </span>
                  )
                )}
              </StyledTableCell>
              <SubscribersCountCell subscriptionId={row._id} />
              <StyledTableCell align="left">
                <div className={s.statusBlock}>
                  <span
                    className={row.isOn ? s.verifiedColumn : s.unverifiedColumn}
                  >
                    {row.isOn ? "On" : "Off"}
                  </span>
                  <MuiSwitch
                    checked={row.isOn}
                    setChecked={() => updateSubStatus(!row.isOn, row._id)}
                  />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter className={s.tableFooter}>
          <TableRow
            sx={{
              minWidth: 700,
              width: "100%",
              border: "none",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              colSpan={5}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{ border: "none", color: "white" }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
