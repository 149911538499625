import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as PlusIcon } from "../../../assets/OtherSettings/add.svg";
import { ReactComponent as CsvFileIcon } from "../../../assets/OtherSettings/csv.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/OtherSettings/refresh.svg";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import MuiDatePicker from "../../../components/MuiDatePicker/MuiDatePicker";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { PriceRotation } from "../../../types/PricingRotation";
import { formatDateAndTime } from "../../../utils/dateActions";
import { downloadFile } from "../../../utils/messageChat";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import s from "./PricingRotation.module.css";

type NewPricingRotation = {
  file: File | undefined;
  endDate: Date;
};

const PricingRotation = () => {
  const token = localStorage.getItem("token");
  const [currentPricing, setCurrentPricing] = useState<
    PriceRotation | undefined
  >();
  const [nextPricingRotation, setNextPricingRotation] = useState<
    PriceRotation | undefined
  >();
  const [newPriceRotation, setNewPriceRotation] = useState<NewPricingRotation>({
    file: undefined,
    endDate: new Date(),
  });
  const [newRotationModal, setNewRotationModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const nextRotationRequest = await SpecialistApi.getNextPriceRotation(
        token
      );
      const currentRotationRequest =
        await SpecialistApi.getCurrentPriceRotation(token);

      const [nextRotationResponse, currentRotationResponse] = await Promise.all(
        [nextRotationRequest, currentRotationRequest]
      );

      if (nextRotationResponse.status && nextRotationResponse.priceRotation) {
        setNextPricingRotation(nextRotationResponse.priceRotation);
      }
      if (
        currentRotationResponse.status &&
        currentRotationResponse.priceRotation
      ) {
        setCurrentPricing(currentRotationResponse.priceRotation);
      }
    })();
  }, []);

  const addNextLevelPriceRotation = async () => {
    if (!newPriceRotation.file || !token) return;
    const formData = new FormData();
    formData.append("pricesFile", newPriceRotation.file);
    formData.append("rotationEndDate", newPriceRotation.endDate.toISOString());

    const response = await SpecialistApi.addNextLevelPriceRotation(
      token,
      formData
    );
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.priceRotation) {
      if (!currentPricing) {
        setCurrentPricing(response.priceRotation);
      }
      if (currentPricing) {
        setNextPricingRotation(response.priceRotation);
      }
      setNewRotationModal(false);
      SuccesNotify("Pricing rotation has been added");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Берём первый выбранный файл
    if (file && file.type === "text/csv") {
      setNewPriceRotation((prev) => ({ ...prev, file: file })); // Сохраняем файл в состояние
    } else {
      Notify("Пожалуйста, выберите файл в формате CSV.");
    }
  };

  return (
    <div className={s.container}>
      <div className={s.pricingSection}>
        <h3>Current pricing:</h3>
        {currentPricing ? (
          <div className={s.pricingInfoBlock}>
            <div>
              Start date:{" "}
              <span className={s.dateValue}>
                {
                  formatDateAndTime(
                    new Date(currentPricing.rotationStartDate),
                    "en"
                  ).formattedDate
                }
              </span>
            </div>
            <div>
              End date:{" "}
              <span className={s.dateValue}>
                {
                  formatDateAndTime(
                    new Date(currentPricing.rotationEndDate),
                    "en"
                  ).formattedDate
                }
              </span>
            </div>
            <StandartButton
              action={() =>
                downloadFile(
                  currentPricing.pricesFile!.link,
                  currentPricing.pricesFile!.filename,
                  currentPricing.pricesFile!.type
                )
              }
              buttonTitle="Download file"
            />
          </div>
        ) : (
          <h4>No data</h4>
        )}
      </div>
      <div className={s.pricingSection}>
        <div className={s.flexGap20Block}>
          <h3>Next pricing rotation:</h3>
          {nextPricingRotation ? (
            <Tooltip title="Change pricing rotation" arrow>
              <div
                className={s.newRotationBtn}
                onClick={() => setNewRotationModal(true)}
              >
                <RefreshIcon width={30} height={30} className={s.actionIcon} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Add pricing rotation" arrow>
              <div
                className={s.newRotationBtn}
                onClick={() => setNewRotationModal(true)}
              >
                <PlusIcon width={50} height={50} className={s.actionIcon} />
              </div>
            </Tooltip>
          )}
        </div>
        {nextPricingRotation ? (
          <div className={s.pricingInfoBlock}>
            <div>
              End date:{" "}
              <span className={s.dateValue}>
                {
                  formatDateAndTime(
                    new Date(nextPricingRotation.rotationEndDate),
                    "en"
                  ).formattedDate
                }
              </span>
            </div>
            <StandartButton
              action={() =>
                downloadFile(
                  nextPricingRotation.pricesFile!.link,
                  nextPricingRotation.pricesFile!.filename,
                  nextPricingRotation.pricesFile!.type
                )
              }
              buttonTitle="Download file"
            />
          </div>
        ) : (
          <h4>No data</h4>
        )}
      </div>
      <ModalWindow
        isOpen={newRotationModal}
        setIsOpen={setNewRotationModal}
        width="fit-content"
      >
        <div className={s.newRotationBlock}>
          <div>
            <span className={s.inputLable}>File with price table</span>
            <label htmlFor="csvFileInput" className={s.selectFileBtn}>
              <CsvFileIcon
                width={24}
                height={24}
                style={{ marginRight: "8px" }}
                className={s.csvIcon}
              />
              <span>
                {newPriceRotation.file
                  ? "Select another file"
                  : "Select CSV file"}
              </span>
            </label>
            <input
              type="file"
              id="csvFileInput"
              accept=".csv"
              style={{ display: "none" }} // Скрываем стандартный инпут
              onChange={handleFileChange}
            />
            {newPriceRotation.file && (
              <p className={s.selectedFileTitle}>
                Selected file: {newPriceRotation.file.name}
              </p>
            )}
          </div>
          <div>
            <span className={s.inputLable}>End date</span>
            <MuiDatePicker
              value={dayjs(newPriceRotation.endDate)}
              setValue={(value) => {
                if (!value) return;
                setNewPriceRotation((prev) => ({
                  ...prev,
                  endDate: value.toDate(),
                }));
              }}
            />
          </div>
          <StandartButton
            action={addNextLevelPriceRotation}
            buttonTitle="Save"
          />
        </div>
      </ModalWindow>
    </div>
  );
};

export default PricingRotation;
