import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import Input from "../../../components/Input/Input";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { siteLangs } from "../../../constans/siteLangs";
import {
  NewCategory as NewCategoryType,
  TaxCategory,
} from "../../../types/TypeSpecialist";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import { areAllTextFieldsFilled } from "../../../utils/validations";
import s from "./TaxCategories.module.css";

const emptyStrings = siteLangs.map((item) => ({
  text: "",
  language: item.value,
}));

const initialCategoryData = {
  title: emptyStrings,
  sessionTaxPercent: 0,
  packageTaxPercent: 0,
  mainSpecialization: "",
};

type NewCategoryProps = {
  setCategoriesData: Dispatch<SetStateAction<TaxCategory[]>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  dataToUpdate?: TaxCategory;
};

const NewCategory = ({
  dataToUpdate,
  setCategoriesData,
  setModalOpen,
}: NewCategoryProps) => {
  const token = localStorage.getItem("token");
  const [selectedLanguages, setSelectedLanguage] = useState("en");
  const [categoryData, setCategoryData] =
    useState<NewCategoryType>(initialCategoryData);
  const [specializationsData, setSpecializationsData] = useState<
    { lable: string; value: string }[]
  >([]);

  useEffect(() => {
    if (!dataToUpdate) return;
    setCategoryData({
      title: dataToUpdate.title,
      sessionTaxPercent: dataToUpdate.sessionTaxPercent,
      packageTaxPercent: dataToUpdate.packageTaxPercent,
      mainSpecialization: dataToUpdate.mainSpecialization._id,
    });
  }, [dataToUpdate]);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await SpecialistApi.getMainSpecializations(token);
      if (response.status && response.mainSpecializations) {
        const specializationData = response.mainSpecializations.map((item) => ({
          lable: item.specialization,
          value: item._id,
        }));
        setSpecializationsData(specializationData);
      }
    })();
  }, []);

  const createCategory = async () => {
    if (!token) return;
    const response = await SpecialistApi.createTaxCategory(token, categoryData);
    if (response.status && response.category) {
      setCategoriesData((prev) => [...prev, response.category!]);
      setModalOpen(false);
      SuccesNotify("Category has been created");
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };
  const updateCategory = async () => {
    if (!token || !dataToUpdate) return;
    const updateData = {
      _id: dataToUpdate._id,
      ...categoryData,
    };
    const response = await SpecialistApi.updateTaxCategory(token, updateData);

    if (response.status && response.category) {
      setCategoriesData((prev) => {
        const updatedData = prev.map((category) => {
          if (category._id === response.category!._id) {
            return response.category!;
          }
          return category;
        });

        return updatedData;
      });

      setModalOpen(false);
      SuccesNotify("Category has been updated");
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };
  const validateData = () => {
    if (
      categoryData.mainSpecialization.length < 1 ||
      !areAllTextFieldsFilled(categoryData.title)
    ) {
      return false;
    }
    return true;
  };
  return (
    <div className={s.createContentBlock}>
      <h3>{dataToUpdate ? "Update " : "Create new "} Category</h3>
      <div className={s.selectLangBlock}>
        <span className={s.lable}>Select localization</span>
        <MultipleSelect
          value={selectedLanguages}
          data={siteLangs}
          multiplie={false}
          setValue={(value) => setSelectedLanguage(value as string)}
          width="100%"
        />
      </div>
      <Input
        inputValue={
          categoryData.title.find((el) => el.language === selectedLanguages)
            ?.text!
        }
        onChangeInput={(value) =>
          setCategoryData((prev) => ({
            ...prev,
            title: prev.title.map((item) =>
              item.language === selectedLanguages
                ? { ...item, text: value }
                : item
            ),
          }))
        }
        isVisible
        required
        lable="Title"
      />
      <Input
        inputValue={categoryData.sessionTaxPercent.toString()}
        onChangeInput={(value) =>
          setCategoryData((prev) => ({
            ...prev,
            sessionTaxPercent: Number(value as string),
          }))
        }
        isVisible
        required
        lable="Sessions tax(%)"
        type="number"
        maxNumber={100}
        minNumber={0}
      />
      <Input
        inputValue={categoryData.packageTaxPercent.toString()}
        onChangeInput={(value) =>
          setCategoryData((prev) => ({
            ...prev,
            packageTaxPercent: Number(value as string),
          }))
        }
        isVisible
        required
        lable="Packages tax(%)"
        type="number"
        maxNumber={100}
        minNumber={0}
      />
      <div>
        <span className={s.lable}>Select specialization</span>
        <MultipleSelect
          data={specializationsData}
          multiplie={false}
          setValue={(value) =>
            setCategoryData((prev) => ({
              ...prev,
              mainSpecialization: value as string,
            }))
          }
          value={categoryData.mainSpecialization}
        />
      </div>
      <StandartButton
        buttonTitle="Save"
        action={dataToUpdate ? updateCategory : createCategory}
        disabled={validateData() ? false : true}
        width="100%"
      />
    </div>
  );
};

export default NewCategory;
