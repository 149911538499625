import { useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as PlusIcon } from "../../../assets/OtherSettings/add.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/OtherSettings/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/OtherSettings/edit.svg";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { TaxCategory } from "../../../types/TypeSpecialist";
import { getTranslation } from "../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import NewCategory from "./NewCategory";
import s from "./TaxCategories.module.css";
const TaxCategories = () => {
  const token = localStorage.getItem("token");
  const [categoriesData, setCategoriesData] = useState<TaxCategory[]>([]);
  const [createModal, setCreateModal] = useState(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState<
    TaxCategory | undefined
  >();
  const [categoryToDeleteId, setCategoryToDeleteId] = useState<
    string | undefined
  >();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await SpecialistApi.getTaxCategories(token);

      if (response.status && response.categories) {
        setCategoriesData(response.categories);
      }
    })();
  }, []);

  useEffect(() => {
    if (createModal) return;
    setCategoryToUpdate(undefined);
  }, [createModal]);

  useEffect(() => {
    if (deleteConfirmModal) return;
    setCategoryToDeleteId(undefined);
  }, [deleteConfirmModal]);

  const deleteCategory = async () => {
    if (!token || !categoryToDeleteId) return;
    const response = await SpecialistApi.deleteTaxCategory(
      token,
      categoryToDeleteId
    );

    if (response.status) {
      setCategoriesData((prev) =>
        prev.filter((el) => el._id !== categoryToDeleteId)
      );
      setDeleteConfirmModal(false);
      SuccesNotify("Category has been deleted");
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  return (
    <div className={s.container}>
      {categoriesData.map((item, index) => (
        <div className={s.categElemntBlock} key={index}>
          <div className={s.actionBlock}>
            <div className={s.actionBtn}>
              <EditIcon
                className={s.editIcon}
                onClick={() => {
                  setCategoryToUpdate(item);
                  setCreateModal(true);
                }}
              />
            </div>
            <div
              className={s.actionBtn}
              onClick={() => {
                setCategoryToDeleteId(item._id);
                setDeleteConfirmModal(true);
              }}
            >
              <DeleteIcon className={s.deleteIcon} />
            </div>
          </div>
          <span className={s.primaryValue}>{getTranslation(item.title)}</span>
          <div className={s.taxBlock}>
            <div className={s.taxElement}>
              <span>Session tax</span>
              <span className={s.primaryValue}>{item.sessionTaxPercent}%</span>
            </div>
            <div className={s.taxElement}>
              <span>Package tax</span>
              <span className={s.primaryValue}>{item.packageTaxPercent}%</span>
            </div>
          </div>
          <div>
            Category for{" "}
            <span className={s.primaryValue}>
              {item.mainSpecialization.specialization}
            </span>
          </div>
        </div>
      ))}
      <div onClick={() => setCreateModal(true)} className={s.addCategoryBtn}>
        <PlusIcon />
      </div>
      <ModalWindow
        isOpen={createModal}
        setIsOpen={setCreateModal}
        width="fit-content"
      >
        <NewCategory
          setCategoriesData={setCategoriesData}
          setModalOpen={setCreateModal}
          dataToUpdate={categoryToUpdate}
        />
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={deleteCategory}
        confirmTitle="Are you sure you want to delete this to do category?"
        modalVisible={deleteConfirmModal}
        setModalVisible={setDeleteConfirmModal}
      />
    </div>
  );
};

export default TaxCategories;
