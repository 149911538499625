import { useEffect, useState } from "react";
import SessionsApi from "../../../api/sessionApi";
import { ReactComponent as ArrowLeft } from "../../../assets/Sessions/arrow-left.svg";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { translations } from "../../../constans/languagesList";
import { RefoundSession, SessionLog } from "../../../types/TypeSession";
import { TypeUserData } from "../../../types/TypeUsers";
import { formatDateAndTime } from "../../../utils/dateActions";
import { getTranslation } from "../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import s from "./AwaitingRefound.module.css";
import SessionsTable from "./SessionsTable/SessionsTable";

interface LogEntry {
  participant: string;
  logs: { joinTime: string; leaveTime: string | null }[];
}

const AwaitingRefound = () => {
  const token = localStorage.getItem("token");
  const [paganation, setPaganation] = useState({
    page: 0,
    rowPerPage: 5,
    total: 0,
  });
  const [sessionsData, setSessionsData] = useState<
    RefoundSession[] | undefined
  >();
  const [selectedSession, setSelectedSession] = useState<
    RefoundSession | undefined
  >();
  const [actionLoaders, setActionLoaders] = useState({ loadSessions: true });
  const [payoutConfirmModal, setPayoutConfirmModal] = useState(false);
  const [refoundConfirmModal, setRefoundConfirmModal] = useState(false);
  const [sessionLogs, setSessionLogs] = useState<LogEntry[]>([]);

  const tagsSteps = ["Direction", "Topic", "Result"];

  const formatMilliseconds = (milliseconds: number): string => {
    const { hours, minutes, seconds } = translations["en"];

    const secs = Math.floor((milliseconds / 1000) % 60);
    const mins = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hrs = Math.floor(milliseconds / (1000 * 60 * 60));

    return `${hrs}${hours} ${mins}${minutes} ${secs}${seconds}`;
  };

  useEffect(() => {
    (async () => {
      if (!token) return;
      setActionLoaders((prev) => ({ ...prev, loadSessions: true }));
      const response = await SessionsApi.getSessionsWaitingForRefund(
        token,
        paganation.page + 1,
        paganation.rowPerPage
      );

      setActionLoaders((prev) => ({ ...prev, loadSessions: false }));
      if (response.sessions && response.status) {
        setSessionsData(response.sessions);
        setPaganation((prev) => ({ ...prev, total: response.amount }));
      }
    })();
  }, [paganation.page, paganation.rowPerPage]);

  const payoutSession = async () => {
    if (!token || !selectedSession) return;
    const payload = {
      userId: selectedSession.clients[0]._id,
      sessionId: selectedSession._id,
    };
    const response = await SessionsApi.payOutSession(token, payload);
    if (response.status) {
      SuccesNotify("Pasyout successful");
      setPayoutConfirmModal(false);
      setSessionsData((prev) =>
        prev?.filter((el) => el._id !== selectedSession._id)
      );
      setSelectedSession(undefined);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  const refundSession = async () => {
    if (!token || !selectedSession) return;
    const payload = {
      userId: selectedSession.specialists[0]._id,
      sessionId: selectedSession._id,
    };
    const response = await SessionsApi.refundSession(token, payload);
    if (response.status) {
      SuccesNotify("Refund successful");
      setRefoundConfirmModal(false);
      setSessionsData((prev) =>
        prev?.filter((el) => el._id !== selectedSession._id)
      );
      setSelectedSession(undefined);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  const transformLogs = (logs: SessionLog[], participants: TypeUserData[]) => {
    // Группируем логи по участникам
    const groupedLogs = logs.reduce<Record<string, SessionLog[]>>(
      (acc, log) => {
        if (!acc[log.invokedBy]) {
          acc[log.invokedBy] = [];
        }
        acc[log.invokedBy].push(log);
        return acc;
      },
      {}
    );

    // Обрабатываем каждого участника
    const result = Object.entries(groupedLogs).map(([participantId, logs]) => {
      const participant = participants.find((p) => p._id === participantId);
      const name = participant
        ? `${getTranslation(participant.name)} ${getTranslation(
            participant.surname
          )}`
        : "Unknown Participant";

      // Сортируем логи по времени
      const sortedLogs = logs.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      // Генерируем пары времени
      const sessionTimes: { joinTime: string; leaveTime: string | null }[] = [];
      let currentJoinTime: string | null = null;

      for (const log of sortedLogs) {
        if (log.action === "joinSession") {
          currentJoinTime = log.date;
        } else if (log.action === "leaveSession" && currentJoinTime) {
          sessionTimes.push({ joinTime: currentJoinTime, leaveTime: log.date });
          currentJoinTime = null;
        }
      }

      // Возвращаем результат
      return { participant: name, logs: sessionTimes };
    });
    setSessionLogs(result);
  };

  useEffect(() => {
    if (!selectedSession) return;
    transformLogs(selectedSession.session_loggings, [
      ...selectedSession.clients,
      ...selectedSession.specialists,
    ]);
  }, [selectedSession]);

  return (
    <div className={s.container}>
      {" "}
      <div className={s.mainContent}>
        <>
          <div
            className={
              selectedSession
                ? `${s.tableBlock} ${s.mobileTableBlockHidden}`
                : `${s.tableBlock} ${s.mobileTableBlockVisible}`
            }
          >
            <SessionsTable
              count={paganation.total}
              page={paganation.page}
              rows={sessionsData ?? []}
              rowsPerPage={paganation.rowPerPage}
              setPagination={setPaganation}
              loader={actionLoaders.loadSessions}
              setSelectedSession={setSelectedSession}
              selectedSession={selectedSession}
            />
          </div>
          <div
            className={
              selectedSession
                ? `${s.sessionInfoBlock} ${s.sessionInfoBlockActive} ${s.mobileSessionVisible}`
                : `${s.sessionInfoBlock} ${s.mobileSessionHidden}`
            }
          >
            {selectedSession && (
              <div>
                <div className={s.buttonsBlock}>
                  <div
                    className={s.backButton}
                    role="buttom"
                    onClick={() => setSelectedSession(undefined)}
                  >
                    <ArrowLeft />
                    <span className={s.backTitle}>Back</span>
                  </div>
                  <div className={s.actionBlock}>
                    <button
                      className={s.actionBtn}
                      onClick={() => setRefoundConfirmModal(true)}
                    >
                      Refound
                    </button>
                    <button
                      className={s.actionBtn}
                      onClick={() => setPayoutConfirmModal(true)}
                    >
                      Payout
                    </button>
                  </div>
                </div>
                <div className={s.infoRow}>
                  {tagsSteps.map(
                    (item, index) =>
                      selectedSession.tags && (
                        <div className={s.infoElement} key={index}>
                          <span className={s.infoLable}>{item}</span>
                          <span className={s.infoValue}>
                            {selectedSession.tags.find(
                              (el) => el.level === index + 1
                            )
                              ? selectedSession.tags
                                  .find((el) => el.level === index + 1)
                                  ?.labels.find((el) => el.language === "en")
                                  ?.text
                              : "-"}
                          </span>
                        </div>
                      )
                  )}
                </div>
                <div className={s.durationBlock}>
                  <h3>Time in session</h3>
                  <div className={s.flexRowBlock}>
                    <div className={s.durationElement}>
                      <span>Client:</span>
                      <span>
                        {formatMilliseconds(
                          selectedSession.sessionDuration?.find(
                            (el) => el.user === selectedSession.clients[0]._id
                          )?.totalSessionTime!
                        )}
                      </span>
                    </div>
                    <div className={s.durationElement}>
                      <span> Specialist:</span>
                      <span>
                        {" "}
                        {formatMilliseconds(
                          selectedSession.sessionDuration?.find(
                            (el) =>
                              el.user === selectedSession.specialists[0]._id
                          )?.totalSessionTime!
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    {sessionLogs.map((entry) => (
                      <div key={entry.participant}>
                        <h3 className={s.participantName}>
                          {entry.participant}
                        </h3>
                        <ul className={s.logsList}>
                          {entry.logs.map((log, index) => (
                            <li key={index} className={s.logItem}>
                              <div className={s.flexRowBlock}>
                                <span className={s.label}>Join time:</span>
                                <span className={s.time}>
                                  {
                                    formatDateAndTime(
                                      new Date(log.joinTime),
                                      "en"
                                    ).formattedTime
                                  }
                                </span>
                              </div>
                              <div className={s.flexRowBlock}>
                                <span className={s.label}>Leave time:</span>
                                <span className={s.time}>
                                  {log.leaveTime
                                    ? formatDateAndTime(
                                        new Date(log.leaveTime),
                                        "en"
                                      ).formattedTime
                                    : "Все еще в сессии"}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedSession.subject && selectedSession.subject.length ? (
                  <div className={s.subjectBlock}>
                    <span className={s.blockLable}>Request title:</span>
                    <span>{selectedSession.subject}</span>
                  </div>
                ) : null}
                {selectedSession.packageRef && (
                  <div className={s.packageBlock}>
                    <span className={s.blockLable}>Session package</span>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>Package title</span>
                      <span className={s.infoRowValue}>
                        {selectedSession.packageRef.title.find(
                          (el) => el.language === "en"
                        )?.text ??
                          selectedSession.packageRef.title[0].text ??
                          "-"}
                      </span>
                    </div>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>Session description:</span>
                      <span className={s.infoRowValue}>
                        {selectedSession.title
                          ? selectedSession.title?.find(
                              (el) => el.language === "en"
                            )?.text ?? selectedSession.title[0].text
                          : "-"}
                      </span>
                    </div>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>Session description:</span>
                      <span className={s.infoRowValue}>
                        {selectedSession.description
                          ? selectedSession.description?.find(
                              (el) => el.language === "en"
                            )?.text ?? selectedSession.description[0].text
                          : "-"}
                      </span>
                    </div>
                  </div>
                )}
                {selectedSession.project && (
                  <div className={s.packageBlock}>
                    <span className={s.blockLable}>Project</span>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>Project title:</span>
                      <span className={s.infoRowValue}>
                        {selectedSession.project?.title ?? "-"}
                      </span>
                    </div>
                    <div /* className={s.rowInfoElement} */>
                      <span className={s.infoLable}>
                        Session description:
                        <span className={s.infoRowValue}>
                          {selectedSession.project?.description ?? "-"}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      </div>
      <ConfirmationModal
        setModalVisible={setPayoutConfirmModal}
        modalVisible={payoutConfirmModal}
        confirmFunc={payoutSession}
        confirmTitle="Are you sure you want to send money to a specialist?"
      />
      <ConfirmationModal
        setModalVisible={setRefoundConfirmModal}
        modalVisible={refoundConfirmModal}
        confirmFunc={refundSession}
        confirmTitle="Are you sure you want to return the client’s money?"
      />
    </div>
  );
};

export default AwaitingRefound;
