import UsersApi from "../api/usersApi";

export const logout = async (userId: string) => {
  await UsersApi.logout({ userId });
  localStorage.removeItem("token");
  localStorage.removeItem("haveCompany");
  localStorage.removeItem("isSpecialist");
  localStorage.removeItem("userHavePrograms");
  window.location.href = "/login";
};
export const calculateAge = (birthday: Date): number => {
  const today: Date = new Date();
  const birthDate: Date = new Date(birthday);
  let age: number = today.getFullYear() - birthDate.getFullYear();
  const monthDiff: number = today.getMonth() - birthDate.getMonth();

  // If the birthday hasn't occurred yet this year, subtract one year
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
