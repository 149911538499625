import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as PlusIcon } from "../../../../assets/Certificates/add.svg";
import EditIcon from "../../../../assets/Certificates/edit.svg";
import Input from "../../../../components/Input/Input";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { imageExtensions } from "../../../../constans/filesExtensions";
import { siteLangs } from "../../../../constans/siteLangs";
import {
  Education,
  Federation,
  Qualification,
} from "../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../types/TypeUsers";
import { compressFiles } from "../../../../utils/compressionFile";
import { getTranslation } from "../../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import {
  areAllTextFieldsFilled,
  validateURL,
} from "../../../../utils/validations";
import s from "../AddCertificatesModal.module.css";

type FederationsCertificates = {
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  specializationsData: { lable: string; value: string }[];
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
  federationToUpdate?: Federation;
};

type NewFederation = {
  newFederation: {
    federationText: { text: string; language: string }[];
    badge: {
      file: File | undefined | string;
      url: string | undefined;
      federationId: string | undefined;
    };
    specialization: string | undefined;
    isShown: boolean;
  };
};

const emptyMultiLangStrings = [
  { text: "", language: "en" },
  { text: "", language: "ru" },
  { text: "", language: "uk" },
];

const initialFederation: NewFederation = {
  newFederation: {
    federationText: emptyMultiLangStrings,
    badge: {
      file: undefined,
      url: undefined,
      federationId: undefined,
    },
    specialization: undefined,
    isShown: true,
  },
};

const FederationsCertificates = ({
  setActionLoaders,
  setModalOpen,
  specializationsData,
  setCertificatesData,
  federationToUpdate,
}: FederationsCertificates) => {
  const _ = require("lodash");
  const token = localStorage.getItem("token");
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const [addFederation, setAddFederation] =
    useState<NewFederation>(initialFederation);
  const [selecLangForText, setSelecLangForText] = useState("en");

  const getUpdatetedFields = () => {
    if (!federationToUpdate) return;
    let updateData: TypeUpdatedUserInfo = { _id: federationToUpdate._id };
    const newData = addFederation.newFederation;
    if (!_.isEqual(newData.federationText, federationToUpdate?.text)) {
      updateData = { ...updateData, text: newData.federationText };
    }
    if (newData.specialization !== federationToUpdate.specialization._id) {
      updateData = { ...updateData, specialization: newData.specialization };
    }
    if (newData.isShown !== federationToUpdate.isShown) {
      updateData = { ...updateData, isShown: newData.isShown };
    }
    if (newData.badge.url !== federationToUpdate.badge?.url) {
      updateData = { ...updateData, badgeUrl: newData.badge.url };
    }
    if (newData.badge.file !== federationToUpdate.badge?.file) {
      updateData = { ...updateData, badgeFile: newData.badge.file };
    }
    return updateData;
  };

  const updateBadge = async (updateData: TypeUpdatedUserInfo) => {
    if (federationToUpdate && !federationToUpdate.badge) {
      const createBadgeResponse = await createFederationBadge();
      if (createBadgeResponse) {
        return createBadgeResponse;
      }
      return;
    }
    if (
      (!updateData.badgeUrl && !updateData.badgeFile) ||
      !federationToUpdate?.badge ||
      !token
    )
      return;
    const updateBadgeData = {
      _id: federationToUpdate?.badge?._id,
      url: updateData.badgeUrl,
    };
    const formData = new FormData();

    formData.append("updateData", JSON.stringify(updateBadgeData));
    if (updateData.badgeFile) {
      formData.append("file", updateData.badgeFile);
    }
    const response = await SpecialistApi.updateFederationBadge(token, formData);

    if (!response.status && response.message) {
      Notify(response.message);
    }
    return { status: response.status, badgeId: response.badge?._id };
  };

  function filterObject(obj: TypeUpdatedUserInfo) {
    const { badgeUrl, badgeFile, ...rest } = obj; // Убираем badgeUrl и badgeFile
    const keys = Object.keys(rest);
    const hasOtherFields = keys.some((key) => key !== "_id");

    return {
      filteredObject: rest,
      hasOtherFields,
    };
  }

  const updateFederation = async (updateData: TypeUpdatedUserInfo) => {
    if (!token) return;
    const response = await SpecialistApi.updateFederation(token, updateData);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.federation) {
      setCertificatesData((prev) => ({
        ...prev,
        federations: prev.federations.map((item) => {
          if (item._id === response.federation?._id) {
            return response.federation!;
          }
          return item;
        }),
      }));
    }
    return { status: response.status, badgeId: response.federation };
  };

  const saveUpdatedFederation = async () => {
    const updateData = getUpdatetedFields();
    if (!updateData) return;
    let updateStatus = true;
    let badgeId: string | undefined = undefined;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    const updateBadgeResponse = await updateBadge(updateData);
    if (updateBadgeResponse) {
      updateStatus = updateBadgeResponse.status;
      badgeId = updateBadgeResponse.badgeId;
    }
    const { filteredObject, hasOtherFields } = filterObject(updateData);
    if (hasOtherFields || badgeId) {
      const updateFederationResponse = await updateFederation({
        ...filteredObject,
        badge: badgeId,
      });
      if (updateFederationResponse) {
        updateStatus = updateFederationResponse.status;
      }
    }
    setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    if (updateStatus) {
      SuccesNotify("Federation has been update");
      setAddFederation(initialFederation);
      setModalOpen(false);
      setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    }
  };

  useEffect(() => {
    function filterByLanguage(
      array1: { text: string; language: string }[],
      array2: { text: string; language: string }[]
    ): { text: string; language: string }[] {
      // Получаем список всех языков из второго массива
      const languagesToRemove = new Set(array2.map((item) => item.language));

      // Фильтруем первый массив, исключая элементы с совпадающим language
      return array1.filter((item) => !languagesToRemove.has(item.language));
    }
    if (!federationToUpdate) return;

    const newLang = filterByLanguage(
      emptyMultiLangStrings,
      federationToUpdate.text
    );
    setAddFederation((prev) => ({
      ...prev,
      newFederation: {
        badge: {
          file: federationToUpdate.badge?.file,
          url: federationToUpdate.badge?.url,
          federationId: undefined,
        },
        federationText: [...federationToUpdate.text, ...newLang],
        isShown: federationToUpdate.isShown,
        specialization: federationToUpdate.specialization._id,
      },
    }));
  }, [federationToUpdate]);

  const handleBadgeIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];

      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddFederation((prev) => ({
            ...prev,
            newFederation: {
              ...prev.newFederation,
              badge: { ...prev.newFederation.badge, file: compressedFile.file },
            },
          }));
        }
      }
    }
  };
  const createFederation = async (badgeId: string) => {
    if (!token) return;
    const payload = {
      federationText: addFederation.newFederation.federationText,
      badgeId: badgeId,
      specialization: addFederation.newFederation.specialization!,
      isShown: addFederation.newFederation.isShown,
    };
    const response = await SpecialistApi.createFederation(token, payload);
    if (response.status && response.federation) {
      setCertificatesData((prev) => ({
        ...prev,
        federations: [response.federation!, ...prev.federations],
      }));
    }
    return {
      status: response.status,
      federationId: response.federation?._id,
      message: response.message,
    };
  };
  const createFederationBadge = async () => {
    if (!token) return;
    const formData = new FormData();

    formData.append("file", addFederation.newFederation.badge.file!);
    formData.append("url", addFederation.newFederation.badge.url!);
    const response = await SpecialistApi.createFederationBadge(token, formData);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return { status: response.status, badgeId: response.badge?._id };
  };
  const saveFederation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let federationId: string | undefined = undefined;
    let badgeId: string | undefined = undefined;

    const badgeResponse = await createFederationBadge();
    badgeId = badgeResponse?.badgeId;

    if (!badgeId)
      return setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    const createFederationResponse = await createFederation(badgeId);
    federationId = createFederationResponse?.federationId;

    if (createFederationResponse && createFederationResponse.status) {
      SuccesNotify("Fediration has been added");
      setAddFederation(initialFederation);
      setModalOpen(false);
      setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    }
    if (
      createFederationResponse &&
      !createFederationResponse.status &&
      createFederationResponse.message
    ) {
      Notify(createFederationResponse.message);
      setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    }
  };

  const validationNewCertificate = () => {
    const newFederation = addFederation.newFederation;
    if (
      !areAllTextFieldsFilled(newFederation.federationText) ||
      !newFederation.badge.file ||
      !validateURL(newFederation.badge.url) ||
      !newFederation.specialization
    )
      return false;
    return true;
  };

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      <div className={s.langsBlock}>
        {siteLangs.map((item, index) => (
          <div
            key={index}
            className={
              selecLangForText === item.value ? s.activeTab : s.notActiveTab
            }
            onClick={() => setSelecLangForText(item.value)}
          >
            {item.lable}
            {selecLangForText === item.value && (
              <span className={s.tabIndicator}></span>
            )}
          </div>
        ))}
      </div>
      <div className={s.newFederationBlock}>
        <Input
          inputValue={getTranslation(
            addFederation.newFederation.federationText,
            selecLangForText
          )}
          isVisible
          required
          onChangeInput={(value) =>
            setAddFederation((prev) => ({
              ...prev,
              newFederation: {
                ...prev.newFederation,
                federationText: prev.newFederation.federationText.map(
                  (item) => {
                    if (item.language === selecLangForText) {
                      return {
                        text: value,
                        language: item.language,
                      };
                    } else return item;
                  }
                ),
              },
            }))
          }
          lable={"Federation title"}
        />
        <Input
          inputValue={addFederation.newFederation.badge.url ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddFederation((prev) => ({
              ...prev,
              newFederation: {
                ...prev.newFederation,
                badge: { ...prev.newFederation.badge, url: value },
              },
            }))
          }
          lable={"Federation bage url"}
          border={
            validateURL(addFederation.newFederation.badge.url) ||
            !addFederation.newFederation.badge.url ||
            !addFederation.newFederation.badge.url.length
              ? "none"
              : "1px solid red"
          }
          placeholder="https://www.credly.com/org/international-coach-federation/badge/icf-member-badge"
        />
        <div>
          <span className={s.secondLable}>{"Badge icon"}</span>
          <div className={s.uploadFilesActionBlock}>
            {addFederation.newFederation.badge.file && (
              <img
                src={
                  typeof addFederation.newFederation.badge.file === "string"
                    ? `${connectionString}specialists/getFederationBadgeStream/${addFederation.newFederation.badge.file}`
                    : URL.createObjectURL(
                        addFederation.newFederation.badge.file
                      )
                }
                alt=""
                className={s.badgeIcon}
              />
            )}
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={imageExtensions.join(", ")}
                multiple
                onChange={handleBadgeIconChange}
              />
              {addFederation.newFederation.badge.file ? (
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>Replace</span>
                </span>
              ) : (
                <div className={s.addCertificateBtn}>
                  <PlusIcon className={s.plusIcon} />
                  <span>Add badge</span>
                </div>
              )}
            </label>
          </div>
        </div>
        <div>
          <span className={s.secondLable}>Specialization</span>
          <MultipleSelect
            data={specializationsData}
            multiplie={false}
            setValue={(value) =>
              setAddFederation((prev) => ({
                ...prev,
                newFederation: {
                  ...prev.newFederation,
                  specialization: value as string,
                },
              }))
            }
            value={addFederation.newFederation.specialization ?? ""}
          />
        </div>
        <div>
          <span className={s.secondLable}>Is verified federation?</span>
          <MuiSwitch
            checked={addFederation.newFederation.isShown}
            setChecked={(value) =>
              setAddFederation((prev) => ({
                ...prev,
                newFederation: { ...prev.newFederation, isShown: value },
              }))
            }
          />
        </div>
      </div>

      <StandartButton
        action={federationToUpdate ? saveUpdatedFederation : saveFederation}
        buttonTitle={"Save"}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default FederationsCertificates;
