import ky from "ky";
import { network } from "../config";
import {
  Subscription,
  SubscriptionFeatures,
  SubscriptionInfo,
  UserSubscription,
} from "../types/Subscription";
import TypeDefaultResponse from "../types/TypeDefaultResponse";

const { subscriptions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "subscriptions/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SubscriptionsAPI {
  static async createSubscription(
    token: string,
    newSubscription: Omit<Subscription, "_id" | "shortInfoList">
  ): Promise<
    TypeDefaultResponse & {
      subscription?: Subscription | null;
    }
  > {
    return await ky
      .post(`${baseURL}${subscriptions.createSubscription}`, {
        headers: getAuthHeader(token),
        json: { newSubscription },
      })
      .then((res) => res.json());
  }

  static async updateSubscription(
    token: string,
    updateData: Partial<
      Omit<
        Subscription,
        | "title"
        | "description"
        | "discount"
        | "accessibleLevels"
        | "durationInDays"
        | "cost"
      >
    >
  ): Promise<
    TypeDefaultResponse & {
      subscription?: Subscription | null;
    }
  > {
    return await ky
      .post(`${baseURL}${subscriptions.updateSubscription}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deleteSubscription(
    token: string,
    subscriptionId: string
  ): Promise<
    TypeDefaultResponse & {
      subscription?: Subscription | null;
    }
  > {
    return await ky
      .post(`${baseURL}${subscriptions.deleteSubscription}`, {
        headers: getAuthHeader(token),
        json: subscriptionId,
      })
      .then((res) => res.json());
  }

  static async getAllSubscriptions(
    token: string,
    paylaod: {
      isOn?: boolean;
      discountFor?: string[];
      accessibleLevels?: string[];
      page: number;
      limit: number;
    }
  ): Promise<
    TypeDefaultResponse & {
      subscriptions?: Subscription[] | null;
      subscriptionsAmount: number;
    }
  > {
    return await ky
      .post(`${baseURL}${subscriptions.getAllSubscriptions}`, {
        headers: getAuthHeader(token),
        json: paylaod,
      })
      .then((res) => res.json());
  }

  static async getActiveSubscribersAmount(
    token: string,
    subscriptionId: string
  ): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${subscriptions.getActiveSubscribersAmount}/${subscriptionId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getMyActiveSubscription(
    token: string
  ): Promise<TypeDefaultResponse & { subscription?: UserSubscription | null }> {
    return await ky
      .get(`${baseURL}${subscriptions.getMyActiveSubscription}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getMySubscriptionHistory(
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .get(`${baseURL}${subscriptions.getMySubscriptionHistory}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async subscribeToSubscription(
    token: string,
    subscriptionId: string,
    daysAmount: number
  ): Promise<
    TypeDefaultResponse & {
      subscription?: Subscription | null;
    }
  > {
    return await ky
      .post(`${baseURL}${subscriptions.subscribeToSubscription}`, {
        headers: getAuthHeader(token),
        json: { subscriptionId, daysAmount },
      })
      .then((res) => res.json());
  }

  static async createSubscriptionShortInfo(
    token: string,
    newShortInfo: FormData,
    subscriptionId: string
  ): Promise<
    TypeDefaultResponse & {
      shortInfo?: SubscriptionInfo;
    }
  > {
    return await ky
      .post(
        `${baseURL}${subscriptions.createSubscriptionShortInfo}/${subscriptionId}`,
        {
          headers: getAuthHeader(token),
          body: newShortInfo,
        }
      )
      .then((res) => res.json());
  }
  static async createSubscriptionFeatures(
    token: string,
    features: FormData,
    subscriptionShortInfoId: string
  ): Promise<
    TypeDefaultResponse & {
      features?: SubscriptionFeatures;
    }
  > {
    return await ky
      .post(
        `${baseURL}${subscriptions.createSubscriptionFeatures}/${subscriptionShortInfoId}`,
        {
          headers: getAuthHeader(token),
          body: features,
        }
      )
      .then((res) => res.json());
  }
}
