import { SessionComment } from "../../../types/TypeSession";
import s from "../Profile.module.css";
import ReviewCard from "./ReviewCard/ReviewCard";

type ReviewsSectionProps = {
  commentsData: SessionComment[];
};

const ReviewsSection = ({ commentsData }: ReviewsSectionProps) => {
  if (!commentsData || !commentsData.length) {
    return null;
  }
  return (
    <div className={s.reviewsBlock}>
      {commentsData && commentsData.length ? (
        <>
          <h4 className={s.blockLable}>Reviews ({commentsData.length})</h4>
          <div className={s.commentsListBlock}>
            {commentsData.map((item, index) => (
              <ReviewCard
                rating={item.rating}
                customComment={item.customComment}
                staticComments={item.defaultComments.map(
                  (item) =>
                    item.labels.find((el) => el.language === "en")?.text!
                )}
                key={index}
              />
            ))}
          </div>
        </>
      ) : null}{" "}
    </div>
  );
};

export default ReviewsSection;
