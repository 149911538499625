import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as PlusIcon } from "../../../../assets/Certificates/add.svg";
import EditIcon from "../../../../assets/Certificates/edit.svg";
import AutocompleteSelect from "../../../../components/AutocompleteSelect/AutocompleteSelect";
import Input from "../../../../components/Input/Input";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { imageExtensions } from "../../../../constans/filesExtensions";
import { siteLangs } from "../../../../constans/siteLangs";
import {
  Education,
  Federation,
  Qualification,
} from "../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../types/TypeUsers";
import { compressFiles } from "../../../../utils/compressionFile";
import { getTranslation } from "../../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import { areAllTextFieldsFilled } from "../../../../utils/validations";
import s from "../AddCertificatesModal.module.css";

type FederationsCertificates = {
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  specializationsData: { lable: string; value: string }[];
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
  qualificationToUpdate?: Qualification;
};

type NewQualification = {
  newQualification: {
    qualificationText: { text: string; language: string }[];
    federation: string | undefined;
    specialization: string | undefined;
    badge: {
      file: File | undefined | string;
      url: string | undefined;
      qualificationId: string | undefined;
    };
    isShown: boolean;
  };
};
const emptyMultiLangStrings = [
  { text: "", language: "en" },
  { text: "", language: "ru" },
  { text: "", language: "uk" },
];
const initialQualification: NewQualification = {
  newQualification: {
    qualificationText: emptyMultiLangStrings,
    federation: undefined,
    specialization: undefined,
    badge: {
      file: undefined,
      url: undefined,
      qualificationId: undefined,
    },
    isShown: true,
  },
};

const QualificationsCertificates = ({
  setActionLoaders,
  setModalOpen,
  specializationsData,
  setCertificatesData,
  qualificationToUpdate,
}: FederationsCertificates) => {
  const _ = require("lodash");
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const token = localStorage.getItem("token");
  const [addQualification, setAddQualification] =
    useState<NewQualification>(initialQualification);
  const [selecLangForText, setSelecLangForText] = useState("en");
  const [federationsData, setFederationsData] = useState<
    { lable: string; value: string }[] | undefined
  >();

  function filterByLanguage(
    array1: { text: string; language: string }[],
    array2: { text: string; language: string }[]
  ): { text: string; language: string }[] {
    // Получаем список всех языков из второго массива
    const languagesToRemove = new Set(array2.map((item) => item.language));

    // Фильтруем первый массив, исключая элементы с совпадающим language
    return array1.filter((item) => !languagesToRemove.has(item.language));
  }

  useEffect(() => {
    if (!qualificationToUpdate) return;

    const newLang = filterByLanguage(
      emptyMultiLangStrings,
      qualificationToUpdate.text
    );

    setAddQualification((prev) => ({
      ...prev!,
      newQualification: {
        badge: {
          file: qualificationToUpdate.badge?.file,
          url: qualificationToUpdate.badge?.url,
          qualificationId: undefined,
        },
        qualificationText: [...qualificationToUpdate.text, ...newLang],
        isShown: qualificationToUpdate.isShown,
        specialization: qualificationToUpdate.specialization._id,
        federation: qualificationToUpdate.federationRef._id,
      },
    }));
  }, [qualificationToUpdate]);

  const getUpdatetedFields = () => {
    if (!qualificationToUpdate) return;
    let updateData: TypeUpdatedUserInfo = { _id: qualificationToUpdate._id };
    const newData = addQualification.newQualification;
    if (!_.isEqual(newData.qualificationText, qualificationToUpdate?.text)) {
      updateData = { ...updateData, text: newData.qualificationText };
    }
    if (newData.federation !== qualificationToUpdate.federationRef._id) {
      updateData = { ...updateData, federationRef: newData.federation };
    }
    if (newData.specialization !== qualificationToUpdate.specialization._id) {
      updateData = { ...updateData, specialization: newData.specialization };
    }
    if (newData.isShown !== qualificationToUpdate.isShown) {
      updateData = { ...updateData, isShown: newData.isShown };
    }
    if (newData.badge.url !== qualificationToUpdate.badge?.url) {
      updateData = { ...updateData, badgeUrl: newData.badge.url };
    }
    if (newData.badge.file !== qualificationToUpdate.badge?.file) {
      updateData = { ...updateData, badgeFile: newData.badge.file };
    }
    return updateData;
  };

  const updateBadge = async (updateData: TypeUpdatedUserInfo) => {
    if (qualificationToUpdate && !qualificationToUpdate.badge) {
      const createBadgeResponse = await createQualificationBadge();
      if (createBadgeResponse) {
        return createBadgeResponse;
      }
      return;
    }
    if (
      (!updateData.badgeUrl && !updateData.badgeFile) ||
      !qualificationToUpdate?.badge ||
      !token
    )
      return;

    const updateBadgeData = {
      _id: qualificationToUpdate?.badge?._id,
      url: updateData.badgeUrl,
    };
    const formData = new FormData();
    formData.append("updateData", JSON.stringify(updateBadgeData));
    if (updateData.badgeFile) {
      formData.append("file", updateData.badgeFile);
    }
    const response = await SpecialistApi.updateFederationBadge(token, formData);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return { status: response.status, badgeId: response.badge?._id };
  };

  function filterObject(obj: TypeUpdatedUserInfo) {
    const { badgeUrl, badgeFile, ...rest } = obj; // Убираем badgeUrl и badgeFile
    const keys = Object.keys(rest);
    const hasOtherFields = keys.some((key) => key !== "_id");

    return {
      filteredObject: rest,
      hasOtherFields,
    };
  }

  const updateQualification = async (updateData: TypeUpdatedUserInfo) => {
    if (!token) return;
    const response = await SpecialistApi.updateQualification(token, updateData);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.qualification) {
      setCertificatesData((prev) => ({
        ...prev,
        qualifications: prev.qualifications.map((item) => {
          if (item._id === response.qualification?._id) {
            return response.qualification!;
          }
          return item;
        }),
      }));
    }
    return { status: response.status, badgeId: response.qualification };
  };

  const saveUpdatedQualification = async () => {
    const updateData = getUpdatetedFields();
    if (!updateData) return;
    let updateStatus = true;
    let badgeId: string | undefined = undefined;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    const updateBadgeResponse = await updateBadge(updateData);
    if (updateBadgeResponse) {
      updateStatus = updateBadgeResponse.status;
      badgeId = updateBadgeResponse.badgeId;
    }
    const { filteredObject, hasOtherFields } = filterObject(updateData);

    if (hasOtherFields || badgeId) {
      const updateFederationResponse = await updateQualification({
        ...filteredObject,
        badge: badgeId,
      });
      if (updateFederationResponse) {
        updateStatus = updateFederationResponse.status;
      }
    }
    setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    if (updateStatus) {
      SuccesNotify("Qualification has been update");
      setAddQualification(initialQualification);
      setModalOpen(false);
      setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !addQualification.newQualification.specialization ||
        !addQualification.newQualification.specialization.length ||
        !token
      )
        return;
      const payload = {
        filters: {
          specialization: [addQualification.newQualification.specialization],
        },
        page: 1,
        limit: 100000,
      };
      const response = await SpecialistApi.getAllFederations(token, payload);
      if (response.status && response.federations) {
        setFederationsData(
          response.federations.map((item) => ({
            lable: getTranslation(item.text),
            value: item._id,
          }))
        );
      }
    })();
  }, [addQualification.newQualification.specialization]);

  const validateURL = (url: string | undefined) => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleBadgeIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];

      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddQualification((prev) => ({
            ...prev,
            newQualification: {
              ...prev.newQualification,
              badge: {
                ...prev.newQualification.badge,
                file: compressedFile.file,
              },
            },
          }));
        }
      }
    }
  };
  const createQualification = async (badgeId: string) => {
    if (!token) return;
    const payload = {
      qualificationText: addQualification.newQualification.qualificationText,
      specialization: addQualification.newQualification.specialization!,
      federationId: addQualification.newQualification.federation!,
      badgeId: badgeId,
      isShown: addQualification.newQualification.isShown,
    };
    const response = await SpecialistApi.createQualification(token, payload);

    if (response.status && response.qualification) {
      setCertificatesData((prev) => ({
        ...prev,
        qualifications: [response.qualification!, ...prev.qualifications],
      }));
    }
    return response;
  };

  const createQualificationBadge = async () => {
    if (!token) return;
    const formData = new FormData();
    formData.append("file", addQualification.newQualification.badge.file!);
    formData.append("url", addQualification.newQualification.badge.url!);
    const response = await SpecialistApi.createFederationBadge(token, formData);
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return { status: response.status, badgeId: response.badge?._id };
  };

  const saveQualification = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));

    let badgeId: string | undefined = undefined;
    const badgeResponse = await createQualificationBadge();
    badgeId = badgeResponse?.badgeId;
    if (badgeId) {
      const createFederationResponse = await createQualification(badgeId);
      if (createFederationResponse && createFederationResponse.status) {
        SuccesNotify("Qualification has been created");
        setAddQualification(initialQualification);
        setModalOpen(false);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
      if (
        createFederationResponse &&
        !createFederationResponse.status &&
        createFederationResponse.message
      ) {
        Notify(createFederationResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };
  const validationNewCertificate = () => {
    const newQualification = addQualification.newQualification;
    if (
      !newQualification.federation ||
      !areAllTextFieldsFilled(newQualification.qualificationText) ||
      !newQualification.badge.file ||
      !validateURL(newQualification.badge.url) ||
      !newQualification.specialization
    )
      return false;

    return true;
  };

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      <div className={s.langsBlock}>
        {siteLangs.map((item, index) => (
          <div
            key={index}
            className={
              selecLangForText === item.value ? s.activeTab : s.notActiveTab
            }
            onClick={() => setSelecLangForText(item.value)}
          >
            {item.lable}
            {selecLangForText === item.value && (
              <span className={s.tabIndicator}></span>
            )}
          </div>
        ))}
      </div>
      <div className={s.newFederationBlock}>
        <Input
          inputValue={getTranslation(
            addQualification.newQualification.qualificationText,
            selecLangForText
          )}
          isVisible
          required
          onChangeInput={(value) =>
            setAddQualification((prev) => ({
              ...prev,
              newQualification: {
                ...prev.newQualification,
                qualificationText: prev.newQualification.qualificationText.map(
                  (item) => {
                    if (item.language === selecLangForText) {
                      return {
                        text: value,
                        language: item.language,
                      };
                    } else return item;
                  }
                ),
              },
            }))
          }
          lable={"Qualification title"}
        />
        <Input
          inputValue={addQualification.newQualification.badge.url ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddQualification((prev) => ({
              ...prev,
              newQualification: {
                ...prev.newQualification,
                badge: { ...prev.newQualification.badge, url: value },
              },
            }))
          }
          lable={"Badge URL"}
          border={
            validateURL(addQualification.newQualification.badge.url) ||
            !addQualification.newQualification.badge.url ||
            !addQualification.newQualification.badge.url.length
              ? "none"
              : "1px solid red"
          }
          placeholder="https://www.credly.com/org/international-coach-federation/badge/icf-member-badge"
        />
        <div>
          <span className={s.secondLable}>Badge icon</span>
          <div className={s.uploadFilesActionBlock}>
            {addQualification.newQualification.badge.file && (
              <img
                src={
                  typeof addQualification.newQualification.badge.file ===
                  "string"
                    ? `${connectionString}specialists/getFederationBadgeStream/${addQualification.newQualification.badge.file}`
                    : URL.createObjectURL(
                        addQualification.newQualification.badge.file
                      )
                }
                alt=""
                className={s.badgeIcon}
              />
            )}
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={imageExtensions.join(", ")}
                multiple
                onChange={handleBadgeIconChange}
              />
              {addQualification.newQualification.badge.file ? (
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>Replace</span>
                </span>
              ) : (
                <div className={s.addCertificateBtn}>
                  <PlusIcon className={s.plusIcon} />
                  <span>Add badge</span>
                </div>
              )}
            </label>
          </div>
        </div>
        <div>
          <span className={s.secondLable}>Specialization</span>
          <MultipleSelect
            data={specializationsData}
            multiplie={false}
            setValue={(value) =>
              setAddQualification((prev) => ({
                ...prev,
                newQualification: {
                  ...prev.newQualification,
                  specialization: value as string,
                },
              }))
            }
            value={addQualification.newQualification.specialization ?? ""}
          />
        </div>
        <div>
          <span className={s.secondLable}>Federation</span>
          <AutocompleteSelect
            data={federationsData ?? []}
            setValue={(value) =>
              setAddQualification((prev) => ({
                ...prev,
                newQualification: {
                  ...prev.newQualification,
                  federation: value as string,
                },
              }))
            }
            multiple={false}
            value={addQualification.newQualification.federation ?? ""}
            disabled={federationsData && federationsData.length ? false : true}
          />
        </div>
        <div>
          <span className={s.secondLable}>Is verified federation?</span>
          <MuiSwitch
            checked={addQualification.newQualification.isShown}
            setChecked={(value) =>
              setAddQualification((prev) => ({
                ...prev,
                newQualification: { ...prev.newQualification, isShown: value },
              }))
            }
          />
        </div>
      </div>

      <StandartButton
        action={
          qualificationToUpdate ? saveUpdatedQualification : saveQualification
        }
        buttonTitle={"Save"}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default QualificationsCertificates;
