import { useEffect, useState } from "react";
import StandartButton from "../../components/StandartButton/StandartButton";
import {
  Education,
  Federation,
  Qualification,
} from "../../types/TypeSpecialist";
import AddCertificatesModal from "./AddCertificatesModal/AddCertificatesModal";
import s from "./Certificates.module.css";
import {
  getAllEducations,
  getAllFederations,
  getAllQualifications,
} from "./certificatesGetters";
import EducationInfo from "./CertificatesInfo/EducationInfo";
import FederationInfo from "./CertificatesInfo/FederationInfo";
import QualificationInfo from "./CertificatesInfo/QualificationInfo";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import EducationsTable from "./Tables/EducationsTable";
import FederationTable from "./Tables/FederationTable";
import QualificationTable from "./Tables/QualificationTable";

type CertificatesTypes = "Educations" | "Qualifications" | "Federations";

export type CertificatesFilters = {
  specialization?: string[];
  isShown?: boolean;
};

type SelectData = {
  ["Educations"]: Education | undefined;
  ["Qualifications"]: Qualification | undefined;
  ["Federations"]: Federation | undefined;
};

const Certificates = () => {
  const token = localStorage.getItem("token");
  const [createEducationModal, setCreateEducationModal] = useState(false);
  const [createQualificationModal, setCreateQualificationModal] =
    useState(false);
  const [createFederationModal, setCreateFederationModal] = useState(false);
  const [selectCertificateType, setSelectCertificateType] =
    useState<CertificatesTypes>("Educations");
  const [paggination, setPaggination] = useState({
    education: { page: 1, limit: 5, count: 0 },
    qualification: { page: 1, limit: 5, count: 0 },
    federation: { page: 1, limit: 5, count: 0 },
  });
  const [filters, setFilters] = useState<{
    educations: CertificatesFilters;
    qualifications: CertificatesFilters;
    federations: CertificatesFilters;
  }>({
    educations: {},
    qualifications: {},
    federations: {},
  });
  const [certificatesData, setCertificatesData] = useState<{
    educations: Education[];
    qualifications: Qualification[];
    federations: Federation[];
  }>({
    educations: [],
    qualifications: [],
    federations: [],
  });
  const [selectedData, setSelectedData] = useState<SelectData>({
    ["Educations"]: undefined,
    ["Qualifications"]: undefined,
    ["Federations"]: undefined,
  });

  const tabsData: CertificatesTypes[] = [
    "Educations",
    "Qualifications",
    "Federations",
  ];
  const certificatesTypeData = {
    ["Educations"]: {
      openModal: () => setCreateEducationModal(true),
      title: "Education",
      table: (
        <EducationsTable
          page={paggination.education.page}
          count={paggination.education.count}
          rows={certificatesData.educations}
          rowsPerPage={paggination.education.limit}
          selectHandler={(id: string) =>
            setSelectedData((prev) => ({
              ...prev,
              ["Educations"]: certificatesData.educations.find(
                (el) => el._id === id
              ),
            }))
          }
          setPage={(page: number) =>
            setPaggination((prev) => ({
              ...prev,
              education: { ...prev.education, page: page },
            }))
          }
          setRowsPerPage={(limit: number) =>
            setPaggination((prev) => ({
              ...prev,
              education: { ...prev.education, limit: limit },
            }))
          }
        />
      ),
      certificateInfo: (
        <EducationInfo
          selectedData={selectedData["Educations"]}
          backHandler={() =>
            setSelectedData((prev) => ({ ...prev, ["Educations"]: undefined }))
          }
          setCertificatesData={setCertificatesData}
        />
      ),
    },
    ["Qualifications"]: {
      openModal: () => setCreateQualificationModal(true),
      title: "Qualification",
      table: (
        <QualificationTable
          page={paggination.qualification.page}
          count={paggination.qualification.count}
          rows={certificatesData.qualifications}
          rowsPerPage={paggination.qualification.limit}
          selectHandler={(id: string) =>
            setSelectedData((prev) => ({
              ...prev,
              ["Qualifications"]: certificatesData.qualifications.find(
                (el) => el._id === id
              ),
            }))
          }
          setPage={(page: number) =>
            setPaggination((prev) => ({
              ...prev,
              qualification: { ...prev.qualification, page: page },
            }))
          }
          setRowsPerPage={(limit: number) =>
            setPaggination((prev) => ({
              ...prev,
              qualification: { ...prev.qualification, limit: limit },
            }))
          }
        />
      ),
      certificateInfo: (
        <QualificationInfo
          selectedData={selectedData["Qualifications"]}
          backHandler={() =>
            setSelectedData((prev) => ({
              ...prev,
              ["Qualifications"]: undefined,
            }))
          }
          setCertificatesData={setCertificatesData}
        />
      ),
    },
    ["Federations"]: {
      openModal: () => setCreateFederationModal(true),
      title: "Federation",
      table: (
        <FederationTable
          page={paggination.federation.page}
          count={paggination.federation.count}
          rows={certificatesData.federations}
          rowsPerPage={paggination.federation.limit}
          selectHandler={(id: string) =>
            setSelectedData((prev) => ({
              ...prev,
              ["Federations"]: certificatesData.federations.find(
                (el) => el._id === id
              ),
            }))
          }
          setPage={(page: number) =>
            setPaggination((prev) => ({
              ...prev,
              federation: { ...prev.federation, page: page },
            }))
          }
          setRowsPerPage={(limit: number) =>
            setPaggination((prev) => ({
              ...prev,
              federation: { ...prev.federation, limit: limit },
            }))
          }
        />
      ),
      certificateInfo: (
        <FederationInfo
          selectedData={selectedData["Federations"]}
          backHandler={() =>
            setSelectedData((prev) => ({ ...prev, ["Federations"]: undefined }))
          }
          setCertificatesData={setCertificatesData}
        />
      ),
    },
  };

  useEffect(() => {
    const payload = {
      filters: filters.educations,
      page: paggination.education.page,
      limit: paggination.education.limit,
    };
    getAllEducations(token, payload, (data) =>
      setCertificatesData((prev) => ({ ...prev, educations: data }))
    );
  }, [
    paggination.education.page,
    paggination.education.limit,
    filters.educations,
  ]);

  useEffect(() => {
    const payload = {
      filters: filters.federations,
      page: paggination.federation.page,
      limit: paggination.federation.limit,
    };
    getAllFederations(token, payload, (data) =>
      setCertificatesData((prev) => ({ ...prev, federations: data }))
    );
  }, [
    paggination.federation.page,
    paggination.federation.limit,
    filters.federations,
  ]);

  useEffect(() => {
    const payload = {
      filters: filters.qualifications,
      page: paggination.qualification.page,
      limit: paggination.qualification.limit,
    };
    getAllQualifications(token, payload, (data) =>
      setCertificatesData((prev) => ({ ...prev, qualifications: data }))
    );
  }, [
    paggination.qualification.page,
    paggination.qualification.limit,
    filters.qualifications,
  ]);

  return (
    <div className={s.container}>
      <div className={s.headerBlock}>
        <div className={s.tabsBlock}>
          {tabsData.map((item, index) => (
            <div
              key={index}
              className={
                selectCertificateType === item ? s.activeTab : s.notActiveTab
              }
              onClick={() => setSelectCertificateType(item)}
            >
              {item}
              {selectCertificateType === item && (
                <span className={s.tabIndicator}></span>
              )}
            </div>
          ))}
        </div>
        <StandartButton
          action={certificatesTypeData[selectCertificateType].openModal}
          buttonTitle={
            "Create " + certificatesTypeData[selectCertificateType].title
          }
        />
      </div>
      <div className={s.filtersBlock}>
        <span className={s.filtersTitle}>Filters:</span>
        <FiltersBlock
          filtersValue={filters}
          selectCertificateType={selectCertificateType}
          setFilters={setFilters}
        />
      </div>
      <div className={s.contentBlock}>
        <div className={s.tableBlock}>
          {certificatesTypeData[selectCertificateType].table}
        </div>
        <div
          className={`${s.infoBlock} ${
            selectedData[selectCertificateType] && s.infoBlockActive
          }`}
        >
          {certificatesTypeData[selectCertificateType].certificateInfo}
        </div>
      </div>
      <AddCertificatesModal
        certificateType="education"
        modalOpen={createEducationModal}
        setModalOpen={setCreateEducationModal}
        modalTitle={"Education"}
        setCertificatesData={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="federation"
        modalOpen={createFederationModal}
        setModalOpen={setCreateFederationModal}
        modalTitle={"Federation"}
        setCertificatesData={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="qualification"
        modalOpen={createQualificationModal}
        setModalOpen={setCreateQualificationModal}
        setCertificatesData={setCertificatesData}
        modalTitle="Qualification"
      />
    </div>
  );
};

export default Certificates;
