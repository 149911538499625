import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as EditIcon } from "../../../assets/Companies/edit.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../assets/General/chevronDown.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/General/delete.svg";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import {
  Education,
  EducationAccreditation,
  Federation,
  Qualification,
} from "../../../types/TypeSpecialist";
import { getTranslation } from "../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import AddCertificatesModal from "../AddCertificatesModal/AddCertificatesModal";
import s from "./CertificatesInfo.module.css";

type EducationInfoProps = {
  selectedData: Education | undefined;
  backHandler: () => void;
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
};

const EducationInfo = ({
  selectedData,
  backHandler,
  setCertificatesData,
}: EducationInfoProps) => {
  const token = localStorage.getItem("token");
  const [updateModal, setUpdateModal] = useState(false);
  const [coursesData, setCoursesData] = useState<
    {
      accreditations: (EducationAccreditation | undefined)[];
      _id: string;
      text: {
        language: string;
        text: string;
      }[];
      isShown: boolean;
      education: Education;
      deleted: boolean;
    }[]
  >([]);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (!selectedData) return;
      const response = await SpecialistApi.getAllEducationCourses(token!, {
        filters: { education: [selectedData._id] },
        page: 1,
        limit: 10000,
      });

      if (response.status && response.courses) {
        const requests = response.courses.map((item) =>
          SpecialistApi.getAllEducationAccreditations(token!, {
            filters: { educationCourse: [item._id] },
            page: 1,
            limit: 100000,
          })
        );
        const accrResponses = await Promise.all(requests);
        const allAccr = accrResponses.map((item) => item.accreditations).flat();

        const courses = response.courses.map((item) => ({
          ...item,
          accreditations: allAccr.filter(
            (el) => el?.educationCourse._id === item._id
          ),
        }));
        setCoursesData(courses);
      }
    })();
  }, [selectedData]);

  const deletedEducation = async () => {
    if (!token || !selectedData) return;
    const response = await SpecialistApi.updateEducation(token, {
      _id: selectedData._id,
      deleted: true,
    });
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.education) {
      setCertificatesData((prev) => ({
        ...prev,
        educations: prev.educations.filter((el) => el._id !== selectedData._id),
      }));
      backHandler();
      setDeleteConfirmModal(false);
      SuccesNotify("Education has been deleted");
    }
  };

  if (!selectedData) {
    return <div></div>;
  }

  return (
    <div className={s.container}>
      <div className={s.headerBlock}>
        <div className={s.backBlock} role="button" onClick={backHandler}>
          <ChevronLeftIcon className={s.chevronBackIcon} />
          Back
        </div>
        <div className={s.actionBlock}>
          <div className={s.editBtn} onClick={() => setUpdateModal(true)}>
            <EditIcon className={s.editIcon} />
          </div>
          <div
            className={s.deleteBtn}
            onClick={() => setDeleteConfirmModal(true)}
          >
            <DeleteIcon className={s.deleteIcon} />
          </div>
        </div>
      </div>
      <div className={s.infoBlock}>
        <span className={s.blockLable}>Title</span>
        <span className={s.textInfoValue}>
          {getTranslation(selectedData.text, "en")}
        </span>
      </div>
      <div className={s.infoBlock}>
        <span className={s.blockLable}>Specialization</span>
        <span className={s.textInfoValue}>
          {selectedData.specialization.specialization}
        </span>
      </div>
      <div className={s.infoBlock}>
        <span className={s.blockLable}>Verify status</span>
        <span
          className={
            selectedData.isShown ? s.verifiedStatus : s.unverifiedStatus
          }
        >
          {selectedData.isShown ? "Verified" : "Not verified"}
        </span>
      </div>

      <AddCertificatesModal
        certificateType="education"
        modalOpen={updateModal}
        setModalOpen={setUpdateModal}
        modalTitle={"Education"}
        setCertificatesData={setCertificatesData}
        educationToUpdate={selectedData}
        coursesToUpdate={coursesData}
      />
      <ConfirmationModal
        confirmFunc={deletedEducation}
        confirmTitle="Are you sure you want to delete this to do education?"
        modalVisible={deleteConfirmModal}
        setModalVisible={setDeleteConfirmModal}
      />
    </div>
  );
};

export default EducationInfo;
