import React, { useState } from "react";
import { ReactComponent as GreenStar } from "../../../../assets/Profile/specialist/green-star.svg";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import s from "./ReviewCard.module.css";

interface ReviewProps {
  customComment?: string;
  staticComments?: string[];
  rating: number;
}

const ReviewCard: React.FC<ReviewProps> = ({
  customComment,
  staticComments,
  rating,
}) => {
  const [showFullReview, setShowFullReview] = useState(false);

  const isLongReview =
    (customComment && customComment.length > 350) ||
    (staticComments && staticComments.length > 5);

  return (
    <div className={s.card}>
      <div className={s.ratingBlock}>
        {Array.from({ length: rating }).map((_, index) => (
          <GreenStar className={s.starIcon} key={index} />
        ))}
        <span>{rating + ".0"}</span>
      </div>
      {customComment && (
        <p className={s.customComment}>
          {customComment.slice(0, 350) +
            (customComment.length > 350 ? "..." : "")}
        </p>
      )}

      {staticComments && (
        <div className={s.staticComments}>
          {staticComments.slice(0, 5).map((comment, index) => (
            <span key={index} className={s.staticComment}>
              {comment}
            </span>
          ))}
        </div>
      )}

      {isLongReview && (
        <button
          className={s.showMoreButton}
          onClick={() => setShowFullReview(true)}
        >
          Show all
        </button>
      )}
      <ModalWindow
        isOpen={showFullReview}
        setIsOpen={setShowFullReview}
        width="fit-content"
      >
        <div className={s.card}>
          <div className={s.ratingBlock}>
            {Array.from({ length: rating }).map((_, index) => (
              <GreenStar className={s.starIcon} key={index} />
            ))}
            <span>{rating + ".0"}</span>
          </div>
          {customComment && <p>{customComment}</p>}

          {staticComments && (
            <div className={s.staticComments}>
              {staticComments.map((comment, index) => (
                <span key={index} className={s.staticComment}>
                  {comment}
                </span>
              ))}
            </div>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default ReviewCard;
