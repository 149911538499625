export const validateURL = (url: string | undefined) => {
  if (!url) return false;
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAllTextFieldsFilled = (
  array: { text: string; language: string }[] | undefined
): boolean => {
  if (!array) return false;
  return array.every((element) => element.text.length > 0);
};
