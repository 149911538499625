import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as ViewFileIcon } from "../../../../assets/Profile/specialist/view-file.svg";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import { EducationCertificatesResponse } from "../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import s from "./Certificates.module.css";

type FederationsProps = {
  specialistId: string;
  isOldProfile: boolean;
};

const Educations = ({ specialistId, isOldProfile }: FederationsProps) => {
  const token = localStorage.getItem("token");
  const [educations, setEducations] = useState<
    EducationCertificatesResponse[] | undefined
  >();
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getEducationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getEducationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };

  const getEducationPublicCertificates = async () => {
    const response = await SpecialistApi.getEducationPublicCertificates(
      specialistId,
      1,
      5
    );

    return { total: response.total, certificates: response.certificates };
  };

  const previewFileHandler = (link: string, type: string) => {
    if (type.includes("pdf")) {
      setPreviewFile({
        imageFile: null,
        pdfFile: link,
      });
    } else {
      setPreviewFile({
        imageFile: link,
        pdfFile: null,
      });
    }
    setPreviewFileModal(true);
  };

  useEffect(() => {
    const makeAsync = async () => {
      try {
        const getEducationCertificatesResponse = !isOldProfile
          ? await getEducationCertificates()
          : await getEducationPublicCertificates();

        setEducations(getEducationCertificatesResponse?.certificates);
      } catch (error) {
        console.error("Error fetching certificates data:", error);
      } finally {
      }
    };
    makeAsync();
  }, [specialistId]);

  useEffect(() => {
    setPreviewFile((prev) => ({ ...prev, imageFile: null }));
  }, []);

  return (
    <div className={s.container}>
      {educations && educations.length ? (
        educations.map((item, index) => (
          <div
            className={s.certificateElement}
            style={{
              backgroundColor: isOldProfile
                ? "rgb(185, 110, 110)"
                : "rgb(106, 148, 102)",
            }}
          >
            <div className={s.mainInfoCertificateBlock}>
              <div className={s.headCertificateBlock}>
                <div>
                  <span>
                    {item.education.text.find((el) => el.language === "en")
                      ?.text ?? item.education.text[0].text}
                  </span>
                  <span className={s.educationDescription}>
                    {item.educationCourse.text.find(
                      (el) => el.language === "en"
                    )?.text ?? item.educationCourse.text[0].text}
                  </span>
                </div>
              </div>
              <div>Hours of study: {item.studyHours + " " + "hours"}</div>
              {item.educationAccreditation && (
                <div>
                  {"Accreditation: " +
                    (item.educationAccreditation.text.find(
                      (el) => el.language === "en"
                    )?.text ?? item.educationAccreditation.text[0].text)}
                </div>
              )}
              <div className={s.bottomCertificateBlock}>
                <span>
                  {formatEducationPeriod(
                    new Date(item.membershipStart),
                    new Date(item.membershipEnd),
                    "en"
                  )}
                </span>
                {item.link && item.link && (
                  <div
                    onClick={() => previewFileHandler(item.link, item.type)}
                    className={s.showCertificateBtn}
                  >
                    <ViewFileIcon className={s.viewCertificateIcon} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <h3>No certificates</h3>
      )}
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default Educations;
