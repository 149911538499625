import { Navigate, Route, Routes } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import Certificates from "../../pages/Certificates/Certificates";
import Companies from "../../pages/Companies/Companies";
import Dashboard from "../../pages/Dashboard/Dashboard";
import GlobalChat from "../../pages/GlobalChat/GlobalChat";
import OtherSettings from "../../pages/OtherSettings/OtherSettings";
import Posts from "../../pages/Posts/Posts";
import Sessions from "../../pages/Sessions/Sessions";
import Specialists from "../../pages/Specialists/Specialists";
import Subscriptions from "../../pages/Subscriptions/Subscriptions";
import Users from "../../pages/Users/Users";
import s from "./Main.module.css";

const Main = () => {
  return (
    <div className={s.container}>
      <div className={s.sideBarMenu}>
        <SideBar />
      </div>
      <div className={s.mainContent}>
        <Routes>
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/specialists" element={<Specialists />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/companies/*" element={<Companies />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/chat" element={<GlobalChat />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/other-settings" element={<OtherSettings />} />
        </Routes>
      </div>
    </div>
  );
};

export default Main;
