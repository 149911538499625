import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import MuiSwitch from "../../../components/MuiSwitch/MuiSwitch";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ServicesConfig, SpecialistData } from "../../../types/TypeSpecialist";
import { TypeUserData } from "../../../types/TypeUsers";
import Notify from "../../../utils/toaster";
import s from "./ServicesConfigs.module.css";

type ServicesConfigsProps = {
  specialistData: SpecialistData;
  setSpecialistData: Dispatch<SetStateAction<TypeUserData[] | undefined>>;
  setSeletedSpecialityInfo: Dispatch<
    SetStateAction<SpecialistData | undefined>
  >;
  setSelectedSpecialist: Dispatch<SetStateAction<TypeUserData | undefined>>;
};
type ServicesConfigData = {
  title: string;
  allowed: boolean;
  objKey: keyof ServicesConfig;
};
const servicesTitles: Record<keyof ServicesConfig, string> = {
  showInCatalog: "Show in catalog",
  showInTreeQuiz: "Show in tree quiz",
  showInSubscription: "Show in subscription",
  showInProjects: "Show in projects",
  reviewRequested: "",
  _id: "",
};

const excludedKeys: Array<keyof ServicesConfig> = ["reviewRequested", "_id"];

const ServicesConfigs = ({
  specialistData,
  setSpecialistData,
  setSeletedSpecialityInfo,
  setSelectedSpecialist,
}: ServicesConfigsProps) => {
  const token = localStorage.getItem("token");
  const [servicesConfigData, setServicesConfigData] = useState<
    ServicesConfigData[]
  >([]);

  useEffect(() => {
    const data = (
      Object.keys(specialistData.servicesConfig) as Array<keyof ServicesConfig>
    )
      .filter((key) => ![...excludedKeys, "__v"].includes(key))
      .map((key) => {
        const service = specialistData.servicesConfig[key];
        const allowed =
          typeof service === "object" && "allowed" in service
            ? service.allowed
            : false;

        return {
          title: servicesTitles[key],
          allowed,
          objKey: key,
        };
      });

    setServicesConfigData(data);
  }, [specialistData]);

  const changeStateValue = (key: keyof ServicesConfig, value: boolean) => {
    setSeletedSpecialityInfo((prev) => ({
      ...prev!,
      servicesConfig: {
        ...prev!.servicesConfig,
        //@ts-expect-error
        [key]: { ...prev.servicesConfig[key], allowed: value },
      },
    }));
    /*   setSelectedSpecialist((prev) => {
      if (!prev || !prev.specialistIds) return prev;
      return {
        ...prev,
        specialistIds: prev.specialistIds.map((item) => {
          if (item._id === specialistData._id)
            return {
              ...item,
              servicesConfig: {
  
                [key]: { ...prev.servicesConfig[key], allowed: value },
              },
            };
          else return item;
        }),
      };
    }); */
    setSpecialistData((prevData) => {
      if (!prevData) return prevData;

      return prevData.map((specialist) => {
        if (
          specialist.specialistIds &&
          specialist.specialistIds
            .map((item) => item._id)
            .includes(specialistData._id)
        ) {
          return {
            ...specialist,
            specialistIds: specialist.specialistIds.map((item) => {
              if (item._id === specialistData._id)
                return {
                  ...item,
                  servicesConfig: {
                    ...item.servicesConfig,
                    //@ts-expect-error
                    [key]: { ...item.servicesConfig[key], allowed: value },
                  },
                };
              else return item;
            }),
          };
        }
        return specialist;
      });
    });
  };

  const reviwedHandler = async () => {
    if (!token) return;

    const updateData = {
      _id: specialistData._id,
      servicesConfig: { reviewRequested: false },
    };
    const response = await SpecialistApi.update(updateData, token);

    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status) {
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        servicesConfig: {
          ...prev!.servicesConfig,
          reviewRequested: false,
        },
      }));
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(specialistData._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === specialistData._id)
                  return {
                    ...item,
                    servicesConfig: {
                      ...item.servicesConfig,
                      reviewRequested: false,
                    },
                  };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };

  const changeStatus = async (key: keyof ServicesConfig, value: boolean) => {
    if (!token) return;
    changeStateValue(key, value);
    const updateData = {
      _id: specialistData._id,
      servicesConfig: { [key]: { allowed: value } },
    };
    const response = await SpecialistApi.update(updateData, token);

    if (!response.status) {
      changeStateValue(key, !value);
      if (response.message) {
        Notify(response.message);
      }
    }
  };

  return (
    <div className={s.container}>
      {specialistData.servicesConfig.reviewRequested && (
        <div className={s.reviewedBlock}>
          <StandartButton
            buttonTitle="Reviewed"
            action={reviwedHandler}
            height="36px"
            width="150px"
          />
        </div>
      )}
      <h5 className={s.description}>
        Here you can choose the services available for a specialist on the Talzi
        platform
      </h5>
      {servicesConfigData.map((item, index) => (
        <div key={index} className={s.serviceAllowedBlock}>
          <div className={s.statusBlockTitle}>
            <span></span>
            <span>{item.title}</span>
          </div>
          <MuiSwitch
            checked={item.allowed}
            setChecked={(value) => changeStatus(item.objKey, value)}
          />
        </div>
      ))}
    </div>
  );
};

export default ServicesConfigs;
