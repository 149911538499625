import { useState } from "react";
import s from "./OtherSettings.module.css";
import PricingRotation from "./PricingRotation/PricingRotation";
import Promocodes from "./Promocodes/Promocodes";
import TaxCategories from "./TaxCategories/TaxCategories";

type TabValueType = "taxCategories" | "promocodes" | "pricingRotation";

const OtherSettings = () => {
  const [activeTab, setActiveTab] = useState<TabValueType>("taxCategories");
  const allTabs: {
    value: TabValueType;
    label: string;
    component: JSX.Element;
  }[] = [
    {
      label: "Tax Categories",
      value: "taxCategories",
      component: <TaxCategories />,
    },
    {
      label: "Promocodes",
      value: "promocodes",
      component: <Promocodes />,
    },
    {
      label: "Pricing rotation",
      value: "pricingRotation",
      component: <PricingRotation />,
    },
  ];
  return (
    <div className={s.contaienr}>
      <div className={s.tabsBlock}>
        {allTabs.map((item, index) => (
          <div
            className={activeTab === item.value ? s.activeTab : s.notActiveTab}
            onClick={() => {
              setActiveTab(item.value);
            }}
            key={index}
          >
            <span>{item.label}</span>
            <span
              className={
                activeTab === item.value
                  ? s.tabActiveIndicator
                  : s.indicatorHidden
              }
            ></span>
          </div>
        ))}
      </div>
      <div className={s.contentBlock}>
        {allTabs.find((el) => el.value === activeTab)?.component}
      </div>
    </div>
  );
};

export default OtherSettings;
