import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import {
  Education,
  EducationAccreditation,
  Federation,
  Qualification,
} from "../../../types/TypeSpecialist";
import s from "./AddCertificatesModal.module.css";
import EducationsCertificates from "./EducationsCertificates/EducationsCertificates";
import FederationsCertificates from "./FederationsCertificates/FederationsCertificates";
import QualificationsCertificates from "./QualificationsCertificates/QualificationsCertificates";

type CoursesToUpdate = {
  accreditations: (EducationAccreditation | undefined)[];
  _id: string;
  text: {
    language: string;
    text: string;
  }[];
  isShown: boolean;
  education: Education;
  deleted: boolean;
}[];

type AddCertificatesModal = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  certificateType: "education" | "qualification" | "federation";
  modalTitle: string;
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
  federationToUpdate?: Federation;
  qualificationToUpdate?: Qualification;
  educationToUpdate?: Education;
  coursesToUpdate?: CoursesToUpdate;
};

const AddCertificatesModal = ({
  modalOpen,
  setModalOpen,
  certificateType,
  modalTitle,
  setCertificatesData,
  federationToUpdate,
  qualificationToUpdate,
  educationToUpdate,
  coursesToUpdate,
}: AddCertificatesModal) => {
  const token = localStorage.getItem("token");
  const [actionLoaders, setActionLoaders] = useState({
    addedCertificate: false,
  });
  const [specializationsData, setSpecializationsData] = useState<
    { lable: string; value: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await SpecialistApi.getMainSpecializations(token);
      if (response.status && response.mainSpecializations) {
        const specData = response.mainSpecializations.map((item) => ({
          lable: item.specialization,
          value: item._id,
        }));
        setSpecializationsData(specData);
      }
    })();
  }, []);

  return (
    <ModalWindow
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      width="fit-content"
    >
      {actionLoaders.addedCertificate ? (
        <div className={s.loaderContainer}>
          <Loader size={100} />
        </div>
      ) : certificateType === "federation" ? (
        <FederationsCertificates
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specializationsData={specializationsData}
          setCertificatesData={setCertificatesData}
          federationToUpdate={federationToUpdate}
        />
      ) : certificateType === "qualification" ? (
        <QualificationsCertificates
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specializationsData={specializationsData}
          setCertificatesData={setCertificatesData}
          qualificationToUpdate={qualificationToUpdate}
        />
      ) : (
        <EducationsCertificates
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specializationsData={specializationsData}
          setCertificatesData={setCertificatesData}
          educationToUpdate={educationToUpdate}
          coursesToUpdate={coursesToUpdate}
        />
      )}
    </ModalWindow>
  );
};

export default AddCertificatesModal;
