import { ReactComponent as ChatIcon } from "../assets/SideBar/chatIcon.svg";
import { ReactComponent as CompaniesIcon } from "../assets/SideBar/comanies.svg";
import { ReactComponent as DashboardIcon } from "../assets/SideBar/dashboard.svg";
import { ReactComponent as EducationIcon } from "../assets/SideBar/education.svg";
import { ReactComponent as Posts } from "../assets/SideBar/posts.svg";
import { ReactComponent as SettingsIcon } from "../assets/SideBar/settings.svg";
import { ReactComponent as SpecialistsIcon } from "../assets/SideBar/specialists.svg";
import { ReactComponent as SessionsIcon } from "../assets/SideBar/swarm-session.svg";
import { ReactComponent as UsersIcon } from "../assets/SideBar/users.svg";
import { ReactComponent as Subscriptions } from "../assets/Subscriptions/subscriptions.svg";

export type TypeMenuItem = {
  pathname: string;
  dataTestId: string;
  icon: any;
  title: string;
  submenu: boolean;
  hidden?: boolean;
  fillStyleIcon?: boolean;
  submenuItems?: {
    title: string;
    path: string;
  }[];
};

export const sidebarMenu: TypeMenuItem[] = [
  {
    pathname: "/dashboard",
    dataTestId: "home-link",
    icon: DashboardIcon,
    title: "Dashboard",
    submenu: false,
  },
  {
    pathname: "/users",
    dataTestId: "home-link",
    icon: UsersIcon,
    title: "Users",
    submenu: false,
  },
  {
    pathname: "/specialists",
    dataTestId: "home-link",
    icon: SpecialistsIcon,
    title: "Specialists",
    submenu: false,
  },
  {
    pathname: "/sessions",
    dataTestId: "home-link",
    icon: SessionsIcon,
    title: "Sessions",
    submenu: false,
    fillStyleIcon: true,
  },
  {
    pathname: "/companies",
    dataTestId: "home-link",
    icon: CompaniesIcon,
    title: "Companies",
    submenu: false,
  },
  {
    pathname: "/subscriptions",
    dataTestId: "home-link",
    icon: Subscriptions,
    title: "Subscriptions",
    submenu: false,
  },
  {
    pathname: "/posts",
    dataTestId: "home-link",
    icon: Posts,
    title: "Posts",
    submenu: false,
  },
  {
    pathname: "/certificates",
    dataTestId: "home-link",
    icon: EducationIcon,
    title: "Certificates",
    submenu: false,
  },
  {
    pathname: "/other-settings",
    dataTestId: "home-link",
    icon: SettingsIcon,
    title: "Other Settings",
    submenu: false,
  },
  {
    pathname: "/chat",
    dataTestId: "home-link",
    icon: ChatIcon,
    title: "Chat",
    submenu: false,
  },
];
