import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import { CertificatesFilters } from "../Certificates";
import s from "./FiltersBlock.module.css";

type FiltersData = {
  lable: string;
  value: string;
};

type FiltersBlockProps = {
  selectCertificateType: "Educations" | "Qualifications" | "Federations";
  filtersValue: {
    educations: CertificatesFilters;
    qualifications: CertificatesFilters;
    federations: CertificatesFilters;
  };
  setFilters: Dispatch<
    SetStateAction<{
      educations: CertificatesFilters;
      qualifications: CertificatesFilters;
      federations: CertificatesFilters;
    }>
  >;
};

const FiltersBlock = ({
  setFilters,
  filtersValue,
  selectCertificateType,
}: FiltersBlockProps) => {
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState<{
    specializations: FiltersData[];
    isShown: FiltersData[];
  }>({ specializations: [], isShown: [] });

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await SpecialistApi.getMainSpecializations(token);
      if (response.status && response.mainSpecializations) {
        const filterSpecializationData = [
          ...response.mainSpecializations.map((item) => ({
            lable: item.specialization,
            value: item._id,
          })),
          {
            lable: "All specializations",
            value: "all",
          },
        ];
        const filterIsShownData = [
          {
            lable: "Only verified",
            value: "true",
          },
          {
            lable: "Only not verified",
            value: "false",
          },
          {
            lable: "All statuses",
            value: "all",
          },
        ];
        setFiltersData((prev) => ({
          ...prev,
          specializations: filterSpecializationData,
          isShown: filterIsShownData,
        }));
      }
    })();
  }, []);

  return (
    <div className={s.container}>
      <MultipleSelect
        data={filtersData.specializations}
        multiplie={false}
        setValue={(value) =>
          setFilters((prev) => ({
            ...prev,
            [selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue]:
              {
                ...prev[
                  selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue
                ],
                specialization: value === "all" ? undefined : [value],
              },
          }))
        }
        value={
          filtersValue[
            selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue
          ].specialization ?? "all"
        }
        width="25%"
      />
      <MultipleSelect
        data={filtersData.isShown}
        multiplie={false}
        setValue={(value) =>
          setFilters((prev) => ({
            ...prev,
            [selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue]:
              {
                ...prev[
                  selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue
                ],
                isShown: value === "all" ? undefined : value === "true",
              },
          }))
        }
        value={
          filtersValue[
            selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue
          ].isShown !== undefined
            ? filtersValue[
                selectCertificateType.toLocaleLowerCase() as keyof typeof filtersValue
              ].isShown!.toString()
            : "all"
        }
        width="25%"
      />
    </div>
  );
};

export default FiltersBlock;
