import { Checkbox, ListItemText, ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import { theme } from "../../utils/theme";
import "./MultipleSelect.css";
import s from "./MultipleSelect.module.css";

type DropdownSelectProps = {
  data: { value: string; lable: string }[];
  setValue: (value: string | string[]) => void;
  value: string | string[];
  multiplie: boolean;
  width?: string;
  lableColor?: string;
  lable?: string;
  checkBox?: boolean;
  height?: string;
  padding?: string;
  disabled?: boolean;
  placeholder?: string;
  bgColor?: string;
  border?: string;
  margin?: string;
};

const MultipleSelect = ({
  data,
  setValue,
  value,
  multiplie,
  width,
  lableColor,
  lable,
  checkBox,
  height,
  padding,
  disabled,
  placeholder,
  bgColor,
  border,
  margin,
}: DropdownSelectProps) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const inputStyle = {
    border: "none",
    "&:focus": {
      borderColor: "red",
    },
  };

  return (
    <div
      style={{ width: width ? width : "100%", margin: margin ?? "0" }}
      className={s.container}
    >
      <ThemeProvider theme={theme}>
        <FormControl
          variant="outlined"
          sx={{ minWidth: 120, width: "100%", margin: 0 }}
        >
          <InputLabel
            shrink={selectIsOpen || value.length ? true : false}
            style={{
              color: "white",
              top: selectIsOpen || value.length ? "-7px" : "-10px",
            }}
          >
            {lable}
          </InputLabel>

          <Select
            multiple={multiplie}
            disabled={disabled ? disabled : false}
            color="secondary"
            onOpen={() => setSelectIsOpen(true)}
            onClose={() => setSelectIsOpen(false)}
            placeholder={placeholder ?? ""}
            style={{
              color: "white",
              borderRadius: "20px",
              backgroundColor: "rgb(46,46,46)",
              borderColor: "red",
              ...inputStyle,
            }}
            labelId={
              checkBox
                ? "demo-multiple-checkbox-label"
                : "demo-multiple-name-label"
            }
            id={checkBox ? "demo-multiple-checkbox" : "demo-multiple-name"}
            //@ts-expect-error
            value={value}
            renderValue={
              checkBox ? (selected: string[]) => selected.join(", ") : undefined
            }
            onChange={(e) => setValue(e.target.value)}
            /*          onFocus={() => setLabelVisible(false)}
          onBlur={() => setLabelVisible(true)} */
            inputProps={{
              style: {
                ...inputStyle,
              },
            }}
            SelectDisplayProps={{
              style: {
                borderRadius: "20px",
                backgroundColor: bgColor ?? "rgb(46,46,46)",
                border: border ?? "none",
                height: height ? height : "auto",
                padding: padding ? padding : "8px 20px",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            MenuProps={{
              style: {
                borderRadius: "20px",
                marginTop: "0px",
                maxHeight: "300px", // Устанавливаем максимальную высоту
              },
            }}
          >
            {data.map((item, index) =>
              checkBox ? (
                <MenuItem
                  key={item.lable}
                  value={item.lable}
                  className="multi-select-menu"
                >
                  <Checkbox checked={value.indexOf(item.lable) > -1} />
                  <ListItemText primary={item.lable} />
                </MenuItem>
              ) : (
                <MenuItem
                  value={item.value}
                  key={index}
                  className="multi-select-menu"
                >
                  {item.lable}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
};

export default MultipleSelect;
