import { Tooltip } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as EditIcon } from "../../../assets/Companies/edit.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../assets/General/chevronDown.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/General/delete.svg";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import {
  Education,
  Federation,
  Qualification,
} from "../../../types/TypeSpecialist";
import { getTranslation } from "../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import AddCertificatesModal from "../AddCertificatesModal/AddCertificatesModal";
import s from "./CertificatesInfo.module.css";

type FederationInfoProps = {
  selectedData: Federation | undefined;
  backHandler: () => void;
  setCertificatesData: Dispatch<
    SetStateAction<{
      educations: Education[];
      qualifications: Qualification[];
      federations: Federation[];
    }>
  >;
};

const FederationInfo = ({
  selectedData,
  backHandler,
  setCertificatesData,
}: FederationInfoProps) => {
  const token = localStorage.getItem("token");
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const deletedFederation = async () => {
    if (!token || !selectedData) return;
    const response = await SpecialistApi.updateFederation(token, {
      _id: selectedData._id,
      deleted: true,
    });
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.federation) {
      setCertificatesData((prev) => ({
        ...prev,
        federations: prev.federations.filter(
          (el) => el._id !== selectedData._id
        ),
      }));
      backHandler();
      setDeleteConfirmModal(false);
      SuccesNotify("Federation has been deleted");
    }
  };

  if (!selectedData) {
    return <div></div>;
  }
  return (
    <div className={s.container}>
      <div className={s.headerBlock}>
        <div className={s.backBlock} role="button" onClick={backHandler}>
          <ChevronLeftIcon className={s.chevronBackIcon} />
          Back
        </div>
        <div className={s.actionBlock}>
          <div className={s.editBtn} onClick={() => setUpdateModal(true)}>
            <EditIcon className={s.editIcon} />
          </div>
          <div
            className={s.deleteBtn}
            onClick={() => setDeleteConfirmModal(true)}
          >
            <DeleteIcon className={s.deleteIcon} />
          </div>
        </div>
      </div>
      <div className={s.contentBlock}>
        <div className={s.infoBlock}>
          <span className={s.blockLable}>Title</span>
          <span className={s.textInfoValue}>
            {getTranslation(selectedData.text, "en")}
          </span>
        </div>
        <div className={s.infoBlock}>
          <span className={s.blockLable}>Specialization</span>
          <span className={s.textInfoValue}>
            {selectedData.specialization.specialization}
          </span>
        </div>
        <div className={s.infoBlock}>
          <span className={s.blockLable}>Verify status</span>
          <span
            className={
              selectedData.isShown ? s.verifiedStatus : s.unverifiedStatus
            }
          >
            {selectedData.isShown ? "Verified" : "Not verified"}
          </span>
        </div>
        {selectedData.badge && (
          <div className={s.infoBlock}>
            <span className={s.blockLable}>Badge</span>
            <Tooltip title={selectedData.badge.url}>
              <img
                src={`${connectionString}specialists/getFederationBadgeStream/${selectedData.badge.file}`}
                alt=""
                className={s.badgeIcon}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <AddCertificatesModal
        certificateType="federation"
        modalOpen={updateModal}
        setModalOpen={setUpdateModal}
        modalTitle={"Federation"}
        setCertificatesData={setCertificatesData}
        federationToUpdate={selectedData}
      />
      <ConfirmationModal
        confirmFunc={deletedFederation}
        confirmTitle="Are you sure you want to delete this to do federation?"
        modalVisible={deleteConfirmModal}
        setModalVisible={setDeleteConfirmModal}
      />
    </div>
  );
};

export default FederationInfo;
