import { Dispatch, SetStateAction, useRef } from "react";
import { ReactComponent as ChevronIcon } from "../../../assets/Social/chevron.svg";
import { Post } from "../../../types/Posts";
import NewsfeedCard from "../NewsfeedCard/NewsfeedCard";
import s from "./CommentsModalContent.module.css";

type CommentsModalContentProps = {
  post: Post;
  allPosts: Post[];
  setAllPosts: Dispatch<SetStateAction<Post[]>>;
  images: string[];
  videos: string[];
  setSeletedImgIndex: Dispatch<SetStateAction<number>>;
  seletedImgIndex: number;
};

const CommentsModalContent = ({
  post,
  allPosts,
  setAllPosts,
  images,
  videos,
  setSeletedImgIndex,
  seletedImgIndex,
}: CommentsModalContentProps) => {
  const postBlockRef = useRef<HTMLDivElement>(null);
  return (
    <div
      className={
        videos.length || images.length ? s.conteiner : s.containerWithoutMedia
      }
    >
      {images.length || videos.length ? (
        <div className={s.mediaBlock}>
          {videos.length ? (
            <div className={s.postVideoBlock}>
              <video src={videos[0]} controls />{" "}
            </div>
          ) : null}
          {images.length ? (
            <div className={s.postImgBlock}>
              <div className={s.bgImg}>
                <img src={images[seletedImgIndex]} alt="" className={s.bgImg} />
              </div>
              {images.length > 1 && (
                <ChevronIcon
                  className={s.chevronLeft}
                  style={{
                    opacity: seletedImgIndex === 0 ? 0.5 : 1,
                  }}
                  onClick={() =>
                    setSeletedImgIndex((prev) => (prev > 0 ? prev - 1 : prev))
                  }
                />
              )}
              {images.length
                ? images.map((item, index) => (
                    <img
                      src={item}
                      alt=""
                      key={index}
                      className={
                        seletedImgIndex === index ? s.postImg : s.postImgHidden
                      }
                    />
                  ))
                : null}
              {images.length > 1 && (
                <ChevronIcon
                  className={s.chevronRight}
                  style={{
                    opacity: images.length - 1 === seletedImgIndex ? 0.5 : 1,
                  }}
                  onClick={() =>
                    setSeletedImgIndex((prev) =>
                      prev < images.length - 1 ? prev + 1 : prev
                    )
                  }
                />
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={s.postCommentsBlock}>
        <div className={s.postBlock} ref={postBlockRef}>
          <NewsfeedCard
            allPosts={allPosts}
            post={post}
            setAllPosts={setAllPosts}
            mainPadding="20px"
            hiddenActionsPost
            showMedia={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentsModalContent;
