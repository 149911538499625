import {
  Autocomplete,
  Checkbox,
  ListItemText,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../utils/theme";
import s from "../MultipleSelect/MultipleSelect.module.css";

type DropdownSelectProps = {
  data: { value: string; lable: string }[];
  setValue: (value: string[] | string) => void;
  value: string[] | string;
  width?: string;
  lable?: string;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
};

const AutocompleteSelect = ({
  data,
  setValue,
  value,
  width,
  lable,
  placeholder,
  multiple = true,
  disabled = false,
}: DropdownSelectProps) => {
  // Приводим value к ожидаемому формату для Autocomplete
  const selectedValue = multiple
    ? data.filter((option) => (value as string[]).includes(option.value))
    : data.find((option) => option.value === value) || null;

  const handleChange = (event: any, newValue: any) => {
    if (multiple) {
      if (Array.isArray(newValue)) {
        setValue(newValue.map((item) => item.value));
      }
    } else {
      setValue(newValue?.value || "");
    }
  };

  return (
    <div style={{ width: width ? width : "100%" }} className={s.container}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          options={data}
          disableCloseOnSelect={multiple}
          value={selectedValue}
          onChange={handleChange}
          getOptionLabel={(option) => option.lable}
          isOptionEqualToValue={(option, val) =>
            option.value === (typeof val === "string" ? val : val?.value)
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {multiple && (
                <>
                  <Checkbox checked={selected} />
                  <ListItemText primary={option.lable} />
                </>
              )}
              {!multiple && <ListItemText primary={option.lable} />}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={lable}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                style: {
                  color: "white",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
          )}
          ChipProps={
            multiple
              ? {
                  style: {
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  },
                }
              : undefined
          }
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "white",
            },
            "& .MuiChip-root": multiple
              ? {
                  backgroundColor: "#1976d2",
                  color: "white",
                }
              : {},
            "& .MuiAutocomplete-tag": {
              borderRadius: "20px",
            },
          }}
          style={{
            borderRadius: "20px",
            backgroundColor: "rgb(46,46,46)",
            color: "white",
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AutocompleteSelect;
