import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactSpoiler } from "react-simple-spoiler";
import PostApi from "../../../api/postsApi";
import UsersApi from "../../../api/usersApi";
import TalziLogo from "../../../assets/Social/postAdmin.png";
import { ReactComponent as VerifyIcon } from "../../../assets/Social/verify.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import { Post } from "../../../types/Posts";
import { formatDateTimeAgo } from "../../../utils/dateActions";
import { getTranslation } from "../../../utils/getTranslation";
import CommentsModalContent from "../CommentsModalContent/CommentsModalContent";
import FileGrid from "../FileGrid/FileGrid";
import s from "./NewsfeedCard.module.css";

type NewsfeedCardProps = {
  post: Post;
  allPosts: Post[];
  setAllPosts: Dispatch<SetStateAction<Post[]>>;
  hiddenActionsPost?: boolean;
  mainPadding?: string;
  showMedia: boolean;
  postVerifyChangAction?: (actionStatus: "verify" | "delete") => void;
};

const NewsfeedCard = ({
  post,
  allPosts,
  setAllPosts,
  hiddenActionsPost,
  mainPadding,
  showMedia,
  postVerifyChangAction,
}: NewsfeedCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const [postFilesLinks, setPostFilesLinks] = useState<{
    images: { link: string; _id: string; filename: string }[];
    videos: { link: string; _id: string; filename: string }[];
  }>({ images: [], videos: [] });
  const [specPostData, setSpecPostData] = useState<{
    mainSpecialization: string;
    isVerified: boolean;
  } | null>(null);
  const [seletedImgIndex, setSeletedImgIndex] = useState(0);

  const getPostFiles = async () => {
    if (!token) return;
    const response = await PostApi.getPostFiles(token, post._id);

    if (response.status) {
      if (response.images) {
        const imagesLinks = response.images.map((item) => ({
          link: item.link,
          _id: item._id,
          filename: item.filename,
        }));
        setPostFilesLinks((prev) => ({ ...prev, images: imagesLinks }));
      }
      if (response.videos) {
        const videosLinks = response.videos.map((item) => ({
          link: item.link,
          _id: item._id,
          filename: item.filename,
        }));
        setPostFilesLinks((prev) => ({ ...prev, videos: videosLinks }));
      }
    }
  };

  useEffect(() => {
    getPostFiles();
  }, [token, post]);

  useEffect(() => {
    const maleAsync = async () => {
      if (!token) return;
      const selectUserFields = "specialistId";
      const selectSpecFields = "isVerified mainSpecializations";
      const response = await UsersApi.getUserSelectedFields(
        token,
        post.createdBy._id,
        selectUserFields,
        selectSpecFields
      );
      if (response.status && !response.userData?.specialistIds) {
        setSpecPostData(null);
      }
      if (response.status && response.userData?.specialistIds) {
        setSpecPostData({
          isVerified: response.userData.specialistIds[0].isVerified,
          mainSpecialization:
            response.userData.specialistIds[0].mainSpecializations[0].labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
        });
      }
    };
    maleAsync();
  }, [token, post, allPosts]);

  return (
    <div className={s.container} style={{ padding: mainPadding ?? "20px" }}>
      <div className={s.avatarNameBlock}>
        <div>
          <div className={s.avatarBlock}>
            {post.isAdminPost ? (
              <img src={TalziLogo} alt="" className={s.adminAvatar} />
            ) : (
              <CircleAvatar
                userId={post.createdBy._id}
                width="40px"
                height="40px"
                fontSize="20px"
              />
            )}
            <div>
              <span className={s.myName}>
                {post.isAdminPost
                  ? "Talzi News"
                  : getTranslation(post.createdBy.name) +
                    " " +
                    getTranslation(post.createdBy.surname)}
                {specPostData && specPostData.isVerified && (
                  <VerifyIcon className={s.verifyIcon} />
                )}
              </span>
              <div className={s.dateBlock}>
                <span>
                  {formatDateTimeAgo(
                    new Date(post.createdAt),
                    userData!.selectedLanguage
                  ) +
                    " " +
                    (new Date(post.createdAt).getFullYear() ===
                    new Date().getFullYear()
                      ? ""
                      : new Date(post.createdAt).getFullYear())}
                </span>
                {specPostData && specPostData.mainSpecialization && (
                  <span>{specPostData.mainSpecialization}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={s.actionsBlock}>
          <StandartButton
            action={
              postVerifyChangAction
                ? () => postVerifyChangAction("verify")
                : () => null
            }
            buttonTitle={"Verify"}
            width="100px"
          />
          <button
            onClick={
              postVerifyChangAction
                ? () => postVerifyChangAction("delete")
                : () => null
            }
            className={s.deletePostBtn}
          >
            Delete
          </button>
        </div>
      </div>
      <div className={s.contentBlock}>
        {showMedia ? (
          <ReactSpoiler
            collapsedSize={110}
            showMoreComponent={
              <div className={s.showMoreBtn}>
                <p>Show more</p>
              </div>
            }
            showLessComponent={
              <div className={s.showMoreBtn}>
                <p>Show less</p>
              </div>
            }
          >
            <pre className={s.postContent} style={{ display: "block" }}>
              {post.content}
            </pre>
          </ReactSpoiler>
        ) : (
          <pre className={s.postContent} style={{ display: "block" }}>
            {post.content}
          </pre>
        )}
        {postFilesLinks.images && postFilesLinks.images.length && showMedia ? (
          <div className={s.imagesBlock}>
            <FileGrid
              images={postFilesLinks.images.map((item) => item.link)}
              setCommentsModalVisible={setCommentsModalVisible}
              setSeletedImgIndex={setSeletedImgIndex}
            />
          </div>
        ) : null}
        {postFilesLinks.videos && postFilesLinks.videos.length && showMedia ? (
          <div className={s.videosBlock}>
            <video
              src={postFilesLinks.videos[0].link}
              controls
              className={s.postVideo}
            />
          </div>
        ) : null}
      </div>

      <ModalWindow
        setIsOpen={setCommentsModalVisible}
        isOpen={commentsModalVisible}
        height={window.innerWidth > 768 ? "80vh" : "90vh"}
        maxWidth="fit-content"
        width={
          postFilesLinks.images.map((item) => item.link).length ||
          postFilesLinks.videos.map((item) => item.link).length
            ? "fit-content"
            : "600px"
        }
        padding="10px 10px 0 0"
      >
        <CommentsModalContent
          allPosts={allPosts}
          post={post}
          setAllPosts={setAllPosts}
          images={postFilesLinks.images.map((item) => item.link)}
          videos={postFilesLinks.videos.map((item) => item.link)}
          seletedImgIndex={seletedImgIndex}
          setSeletedImgIndex={setSeletedImgIndex}
        />
      </ModalWindow>
    </div>
  );
};

export default NewsfeedCard;
